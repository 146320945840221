<template>
  <div style="padding-bottom:.30rem">
    <div class="top">
      <div class="title">{{ info.pbName }}</div>
      <div v-if="info.isFindPb" class="isGood redDiv">{{ info.pbHonorName }}</div>
      <div class="address">地址：{{ info.address || '--' }}</div>
      <div class="fourItem">
        <div class="item redDiv">
          <div class="title">党员数量</div>
          <div class="count">{{ info.pmCount }}</div>
        </div>
        <div class="item redDiv">
          <div class="title">下级党组织</div>
          <div class="count">{{ info.childCount }}</div>
        </div>
        <div class="item redDiv">
          <div class="title">专题数</div>
          <div class="count">{{ info.newsCount }}</div>
        </div>
        <div class="item redDiv">
          <div class="title">活动会议</div>
          <div class="count">{{ info.meetingCount }}</div>
        </div>
      </div>
      <div class="summary">
        <div class="title">组织概述：</div>
        <div class="content">{{ info.summary }}</div>
      </div>

    </div>
    <div class="line3" />

    <div v-if="info.leaderData && info.leaderData.length" class="rich">
      <div class="title">
        <div class="left" />
        <div class="text">领导班子（{{ info.leaderData.length }}）</div>
      </div>
      <div class="content2">
        <div
          v-for="(item, index) in info.leaderData"
          :key="index"
          class="contentItem"
          style="padding-right: .20rem"
        >
          <img class="img" :src="$beforeUrl(item.avatar)">
          <div class="name" style="margin-top:.07rem">{{ item.pmName }}</div>
          <div class="jobName" style="margin-top:.08rem;color:#666;font-size:.13rem">{{ item.jobName }}</div>
          <div :class="{ hide: !item.isBestGuys }" class="youXiu center" style="margin-top:.07rem">
            {{ item.honoraryTitle }}
            <div class="youXiuBg" />
          </div>
        </div>
      </div>
    </div>

    <div class="line3" />

    <div v-if="info.guysData && info.guysData.length" class="rich">
      <div class="title">
        <div class="left" />
        <div class="text">党组成员（{{ info.guysData.length }}）</div>
      </div>
      <div class="content2">
        <div
          v-for="(item, index) in info.guysData"
          :key="index"
          class="contentItem"
          style="padding-right: .20rem"
        >
          <img class="img" :src="$beforeUrl(item.avatar)">
          <div class="name" style="margin-top:.07rem;margin-bottom:.07rem">{{ item.pmName }}</div>
          <div :class="{ hide: !item.isBestGuys }" class="youXiu center">
            {{ item.honoraryTitle }}
            <div class="youXiuBg" />
          </div>
        </div>
      </div>
    </div>

    <div class="line3" />

    <div v-if="info.notes" class="rich">
      <div class="title">
        <div class="left" />
        <div class="text">组织简介</div>
      </div>
      <div class="content" v-html="$setContent(info.notes)" />
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      info: {},
      param: {
        id: ''
      }
    }
  },
  watch: {
    $route() {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.param = this.$getParams()
      this.$store
        .dispatch('main/PbGetPbInfo', { id: this.param.id })
        .then((res) => {
          if (res.code === 0) {
            this.info = res.data
          }
        })
    },
    goBack() {
      this.$router.push({ path: '/GetPbDem' })
    }
  }
}
</script>

<style lang="scss" scoped>
 .top{
    background: #fff;
    padding: .25rem .16rem .25rem .16rem;
    .title{
      font-size: .20rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: .15rem;
    }
    .isGood{
      margin-bottom: .15rem;
      width: unset!important;
      height: .24rem;
      background: rgba(194, 22, 22, 0.1);
      border: 1px solid var(--theme);
      padding: 0 .1rem;
      line-height: .24rem;
    }
    .address{
      font-size: .13rem;
      font-weight: 500;
      color: #999999;
      margin-bottom: .3rem;
    }
    .fourItem{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item{
        width: 1.65rem;
        height: .90rem;
        margin-bottom: .13rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
          font-size: .20rem;
          color: var(--theme);
        }
        .count{
          font-size: .16rem;
        }
      }
    }
    .summary{
      margin-top: .17rem;
      .title{
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
        margin-bottom: .2rem;
      }
    }
    .goDetail{
      margin-top: .35rem;
      height: .44rem;
      background: var(--theme);
      border-radius: 2px;
      font-size: .16rem;
      font-weight: bold;
      color: #FFFFFF;
    }
  }

  .rich {
    width: 100%;
    padding-left:.16rem ;
     .content2 {
    display: flex;
    overflow-x: auto;
    width: 100%;
    .contentItem {
      box-sizing: content-box;
      width: 1.00rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .2rem;
      .img {
        width: 1rem;
        height: 1.4rem;
      }
      .name {
        font-size: .16rem;
        font-weight: 400;
        color: #333333;
        text-align: center;
      }
      .youXiu {
        height: .24rem;
        width: max-content;
        border: 1px solid var(--theme);
        border-radius: 4px;
        font-size: .12rem;
        font-weight: 400;
        color: var(--theme);
        position: relative;
        overflow: hidden;
        padding: 0 .1rem;
        .youXiuBg {
          background: var(--theme);
          opacity: 0.1;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: -1;
        }
      }
    }
  }
    .title {
      display: flex;
      align-items: center;
      margin-bottom: .20rem;
      .left {
        width: .04rem;
        height: .2rem;
        background: var(--theme);
        margin-right: .07rem;
      }
      .text {
        font-size: .20rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: .32rem;
      }
    }
  }

  .line3 {
    height: 1px;
    background: #eeeeee;
    margin-bottom: .25rem;
    width: 3.43rem;
    margin-left: auto;
    margin-right: auto;
  }

  .hide{
    visibility: hidden;
  }

</style>
