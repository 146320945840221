<template>
  <div v-if="selected==0" class="oneItems">
    <div v-if="dataList.length" class="oneItemsNext">
      <div v-for="(item,index) in dataList" :key="index" class="oneTitle click" @click="$go(`/home/Secretary/ArticleDetail?id=${item.id}`)">
        <div class="right">
          <div class="title twoLine">{{ item.title }}</div>
          <div v-if="item.createTime" class="createTime">发布时间：{{ item.createTime.split(' ')[0] }}</div>
        </div>
        <img v-if="item.thumbnail && !onlyText" :src="$beforeUrl(item.thumbnail)">
      </div>
    </div>
    <!-- <div v-else-if="!$parent.loading && !noShowEmpty" style="flex: 1; font-size: 1.00rem" class="colCenter it">
      <img :src="require('@/assets/empty.png')">
      <span>暂无内容</span>
    </div> -->
  </div>
  <div v-else class="oneItems gzsj">
    <div v-if="dataList.length" class="oneItemsNext">
      <div v-for="(item,index) in dataList" :key="index" class="oneTitle click">
        <div class="top">
          <div class="name">{{ item.firstUser }}</div>
          <div v-if="item.createTime" class="createTime">{{ item.createTime.split(' ')[0] }}</div>
        </div>
        <div class="bottom twoLine" v-html="$setContent(item.context)" />
      </div>
    </div>
    <!-- <div v-else-if="!$parent.loading && !noShowEmpty" style="flex: 1; font-size: 1.00rem" class="colCenter it">
      <img :src="require('@/assets/empty.png')">
      <span>暂无内容</span>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    onlyText: {
      type: Boolean,
      default: false
    },
    noShowEmpty: {
      type: Boolean,
      default: false
    }

  },
  data() {
    return {
      selected: 0
    }
  },
  watch: {
    $route() {
      this.selected = this.$getParams().selected || 0
    }
  },
  created() {
    this.selected = this.$getParams().selected || 0
  }

}
</script>

<style lang="scss" scoped>
  .oneItems{
    .oneTitle{
      display: flex;
      justify-content: space-between;
      padding-bottom: .2rem;
      border-bottom: 1px solid #eee;
      margin-bottom: .2rem;
      img{
        width: 1.125rem;
        height: .75rem;
        border-radius: 2px;
        margin-left: .26rem;
      }
      .right{
        overflow: hidden;
        flex:1;
        min-height: .705rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title{
          font-size: .17rem;
        font-weight: bold;
        color: #333333;
          line-height: .26rem;
        }
        .content{
          margin-top: 19px;
        }
        .createTime{
          font-size: .13rem;
          font-weight: 500;
          color: #999999;
        }
      }
    }
    .oneItemsNext>.oneTitle:last-child{
      padding-bottom: unset!important;
      border-bottom: unset!important;
      margin-bottom: unset!important;
    }
  }
  .gzsj{
    .oneTitle{
      display: block!important;
      .top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name{
          font-size: .20rem;
          font-weight: bold;
          color: #333333;
        }
        .createTime{
          font-size: .13rem;
          font-weight: 500;
          color: #999999;
        }
      }
      .bottom{
        line-height: .32rem;
        margin-top: .08rem;
      }
    }
  }

</style>
