<template>
  <contents :init-query="{type:'监督举报'}" :content-data="contentData" :method-path="methodPath" />
</template>

<script>
import contents from '@/components/contentComponent/content.vue'
import textClass from '../common.js'
export default {
  components: { contents },
  data() {
    return {
      contentData: [
        { id: 'title', label: '', style: { color: '#333333', fontSize: '18px' }, class: ['oneLine', 'titleClass'] },
        { id: 'type', label: '类型' },
        { id: 'strStatus', label: '状态', type: 'status', textClass: (val, routeName) => textClass(val, routeName) },
        { id: 'createTime', label: '时间' }
      ],
      methodPath: { // 接口必传
        search: 'personal/ConsultingGetUserConsultList' // 查找接口
      }

    }
  },

  mounted() {

  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
