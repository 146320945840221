<template>
  <div
    v-if="!loading"
    id="app"
    :style="{
      '--theme': $store.getters.theme,
    }"
  >
    <component
      :is="navComponent"
      v-show="!hiddenNavTop.includes($route.path)"
      class="navTop"
    />
    <!-- 修改主题色等 -->

    <router-view class="content" />

    <component
      :is="footerCompontent"
      v-show="showFooter.includes($route.path)"
      class="footer"
    />

    <changeTheme />
  </div>
  <div v-else v-loading="loading" class="loadingApp" />
</template>

<script>
import changeTheme from '@/components/changeTheme/changeTheme.vue'
import wxInitConfig from '@/utils/weixinJsSdk.js'
export default {
  components: {
    changeTheme
  },
  data() {
    return {
      loading: true,
      hiddenNavTop: ['/home', '/mine', '/404', '/401', '/home/search', '/home/login', '/home/login/register', '/home/login/forget'], // 不显示顶部导航栏的路由path
      showFooter: ['/home', '/menu', '/server', '/idea', '/mine'], // 显示底部导航的
      navComponent: () => import('@/views/navTop/index.vue'),
      footerCompontent: () => import('@/views/footer/index.vue')
    }
  },
  watch: {
    $route() {
      // console.log('1')
      this.setRouterName()
      this.$store.commit('app/SET_ROUTEHISTORY') // 记录路由情况
      this.setShare()
    }
  },
  created() {
    this.listenBack() // 监听手机自带的返回键
    this.setRouterName()
    const userInfo = window.localStorage.getItem('userInfos')
    if (userInfo && userInfo != 'undefined') {
      this.$store.commit('app/SET_USERINFO', JSON.parse(userInfo))
      this.$store.dispatch('login/AccountGetAccountInfo', { id: this.$store.getters.userInfo.id }).then(res => {
        if (res.code == 0) {
          this.$store.commit('app/CHANGE_USERINFO', res.data)
        }
      })
    }

    this.$store
      .dispatch('app/HomeGetSite', {
        domain: window.location.protocol + '//' + window.location.host
      })
      .then((res) => {
        this.loading = false
        this.$store.commit('app/SET_MODULENAME', res.data.wapTempId) // 设置模板
        this.$store.commit('app/SET_COLOR', res.data.wapColor) // 设置主题色
        this.$store.commit('app/SET_USERID', res.data.userId) // 设置userId
        this.$store.commit('app/SET_INFO', res.data) // 存储全部
        document.querySelector('link[rel="icon"]').href = this.$beforeUrl(res.data.waplogo)
        this.setShare()
        document.title = res.data.name
      })

    this.setRem() // 设置根字体大小
  },
  methods: {
    setRouterName() {
      const name = this.$route.meta.name
      if (name) {
        this.$store.commit('app/SET_ROUTENAME', name)
      }
    },
    setRem() {
      // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
      const htmlWidth =
      document.documentElement.clientWidth || document.body.clientWidth
      // 得到html的Dom元素
      const htmlDom = document.getElementsByTagName('html')[0]
      htmlDom.style.fontSize = htmlWidth / 375 * 100 + 'px' // rem 与 px 1:100
      window.onresize = () => {
        this.setRem()
      }
    },
    listenBack() {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', () => {
        this.$goBack() // 第一次同路径跳转会报错,阻止了系统默认的跳转
        this.$goBack() // 第二次才真真跳转
      })
    },
    setShare(dataInfo) {
      const path = this.$route.path
      this.param = this.$getParams()
      if (this.param.openid && path === '/home/login') {
        return
      }
      if (path === '/menu/main/detail') return
      const data = this.$store.state.app.info
      if (!data.waplogo) return
      const info = { title: data.name, desc: data.describe, imgUrl: this.$beforeUrl(data.waplogo) }
      wxInitConfig.apply(this, [info])
    }

  }
}
</script>

<style lang="scss">

* {
  font-family: PingFang SC;
}
.el-message {
  min-width: 3.1rem !important;
  width: 3.1rem !important;
  font-size: 0.12rem;
}

.el-message-box {
  min-width: 3.1rem !important;
  width: 3.1rem !important;
}

.el-input .el-input__inner {
  background-color: #f2f3f5;
  border: unset !important;
}

.el-dialog {
  width: 3.15rem !important;
  background: #ffffff;
  border-radius: 5px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;
  }
  font-size: 12px;
}
.aliCenter {
  display: flex;
  align-items: center;
}

//弹框样式,全局
.el-dialog__wrapper {
  display: flex;
  align-items: center;
  .el-dialog {
    margin-top: unset !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    .el-dialog__body {
      flex: 1;
    }
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.colCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.oneLine {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block !important;
  word-break: break-all;
}

.twoLine {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.newOneline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.it {
  //为空的样式
  span {
    font-size: 0.16rem;
    font-weight: 400;
    color: #999999;
    line-height: 0.52rem;
    margin-top: 0.18rem;
  }
  img {
    width: 3.6rem;
    height: 3rem;
  }
}

.rich {
  //解决富文本适配问题
  flex: 1;
  word-break: break-all;
  overflow-x: hidden;
  img {
    height: auto !important;
  }
  * {
    font-size: 0.12rem;
    max-width: 100%;
    width: 100%;
  }
}

.redDiv {
  //红色的框
  width: 0.49rem;
  height: 0.19rem;
  border-radius: 2px;
  font-size: 0.12rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: var(--theme);
  position: relative;
  line-height: 0.19rem;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  &::after {
    content: "";
    display: inline-block;
    background: var(--theme);
    opacity: 0.1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.greenDiv {
  //绿色的框
  width: 0.49rem;
  height: 0.19rem;
  border-radius: 2px;
  font-size: 0.12rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #2ebc74;
  position: relative;
  line-height: 0.19rem;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  &::after {
    content: "";
    display: inline-block;
    background: #2ebc74;
    opacity: 0.1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.cancelDiv {
  //取消的框
  width: 0.49rem;
  height: 0.19rem;
  border-radius: 2px;
  font-size: 0.12rem;
  font-weight: 400;
  color: #999999;
  position: relative;
  line-height: 0.19rem;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  &::after {
    content: "";
    display: inline-block;
    background: #999999;
    opacity: 0.1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.waitDiv {
  //待审批的框
  width: 0.49rem;
  height: 0.19rem;
  border-radius: 2px;
  font-size: 0.12rem;
  font-weight: 400;
  color: #ff8610;
  position: relative;
  line-height: 0.19rem;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  &::after {
    content: "";
    display: inline-block;
    background: #ff8610;
    opacity: 0.1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.goImg {
  width: 0.18rem;
  height: 0.18rem;
}
.vanList{
  height: 100vh;
}

</style>

