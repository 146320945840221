<template>
  <div class="one" style="margin-top:.10rem">
    <div v-loading="lunboLoading" class="lunboOut" style="flex:1;width:3.43rem;margin:auto;border-radius:10px;overflow:hidden">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in dataList" :key="index" style="border-radius:10px;">
          <div class="lunbo" style="border-radius:10px;" @click="$go(item.linkUrl)">
            <img class="lunboImg" :src="$beforeUrl(item.img)">
          </div>
        </van-swipe-item>
      </van-swipe>

    </div>

  </div>
</template>

<script>
import { Swipe, SwipeItem } from 'vant'
export default {
  components: {
    'van-swipe-item': SwipeItem,
    'van-swipe': Swipe
  },
  data() {
    return {
      dataList: [
      ],
      lunboLoading: true
    }
  },

  mounted() {

  },
  created() {
    this.getInfo()
  },

  methods: {
    getInfo() {
      const top = 10
      const position = 'banner'
      const tempId = this.$store.getters.module
      this.$store.dispatch('home/HomeGetSiteAd', { top, position, tempId }).then(res => {
        if (res.code === 0 && res.data[0] && res.data[0].jsonText) {
          this.dataList = JSON.parse(res.data[0].jsonText)
        }
        this.lunboLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .van-swipe__indicator{
    background: var(--theme)!important;

  }
  .van-swipe__track{
    height: 2.28rem!important;
  }
  .van-swipe{
    height: 2.54rem!important;
  }
}
  .lunboImg{
    width: 3.43rem;
    height: 2.28rem;
    border-radius:10px;
  }
  .lunbo{
    overflow: hidden!important;
  }

</style>
