<template>
  <div v-loading="loading" class="video">
    <titleStyle more-url="/menu/main?id=f6f0619816944082b8d2ea5fa1e5da8c&type=2" text="党建视频" />
    <div v-if="videoList.length" class="topVideo" @click="$go(`/menu/main/detail?id=${videoList[0].id}`)">
      <div class="img">
        <img :src="$beforeUrl(videoList[0].videoPoster)">
        <div class="imgText">
          <img class="startIcon" :src="require('@/assets/template/first/home/startIcon.png')">
          <div class="during">{{ formatSeconds(videoList[0].during) }}</div>
        </div>
      </div>
      <div class="title twoLine">{{ videoList[0].title }}</div>
      <div class="createTime">发布时间：{{ videoList[0].createTime.split(' ')[0] }}</div>
    </div>
    <div class="middleVideoOut">
      <div v-for="(item,index) in videoList.slice(1,3)" :key="index" class="middleVideo" @click="$go(`/menu/main/detail?id=${item.id}`)">
        <div class="img">
          <img :src="$beforeUrl(item.videoPoster)">
          <div class="imgText">
            <img class="startIcon" :src="require('@/assets/template/first/home/startIcon.png')">
            <div class="during">{{ formatSeconds(item.during) }}</div>
          </div>
        </div>
        <div class="title twoLine">{{ item.title }}</div>
        <div class="createTime">发布时间：{{ item.createTime.split(' ')[0] }}</div>
      </div>
    </div>

    <div class="middleVideoOut">
      <div v-for="(item,index) in videoList.slice(3,5)" :key="index" class="middleVideo" style="border-bottom:unset;padding-bottom:unset;" @click="$go(`/menu/main/detail?id=${item.id}`)">
        <div class="img">
          <img :src="$beforeUrl(item.videoPoster)">
          <div class="imgText">
            <img class="startIcon" :src="require('@/assets/template/first/home/startIcon.png')">
            <div class="during">{{ formatSeconds(item.during) }}</div>
          </div>
        </div>
        <div class="title twoLine">{{ item.title }}</div>
        <div class="createTime">发布时间：{{ item.createTime.split(' ')[0] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import titleStyle from './titleStyle.vue'
export default {
  components: {
    titleStyle
  },
  data() {
    return {
      videoList: [],
      loading: true
    }
  },

  created() {
    this.$store.dispatch('home/HomeGetVideoNews', { top: 5 }).then((res) => {
      if (res.code === 0) {
        res.data.forEach((item) => {
          const audio = new Audio(this.$beforeUrl(item.video))
          item.during = ''
          audio.addEventListener('loadedmetadata', function(e) {
            item.during = audio.duration
          })
        })
        this.videoList = res.data
      }
      this.loading = false
    })
  },

  methods: {
    formatSeconds(value) {
      if ((value + '').split('.').length > 2) return
      if (!value) return

      var theTime = parseInt(value)// 秒
      var theTime1 = 0// 分
      var theTime2 = 0// 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
        }
      }

      var result = '' + parseInt(theTime)// 秒
      if (theTime < 10 > 0) {
        result = '0' + parseInt(theTime)// 秒
      } else {
        result = '' + parseInt(theTime)// 秒
      }

      if (theTime1 < 10 > 0) {
        result = '0' + parseInt(theTime1) + ':' + result// 分，不足两位数，首位补充0，
      } else {
        result = '' + parseInt(theTime1) + ':' + result// 分
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + ':' + result// 时
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
  .video{
    padding: .2rem .16rem;

    .topVideo{
      border-bottom: 1px solid #eee;
      padding-bottom: .2rem;
      .img{
        width: 3.43rem;
        height: 2.285rem;
        &>img{
          width: 3.43rem;
          height: 2.285rem;
        }
        position: relative;
        .imgText{
          padding: .06rem .075rem;
          position: absolute;
          right: .1rem;
          bottom: .1rem;
          background: rgba(0,0,0,.6);
          border-radius: 2px;
          display: flex;
          align-items: center;
          img{
            width: .11rem;
            height: .11rem;
            margin-right: .04rem;
          }
          .during{
            font-size: .13rem;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }
    .middleVideoOut{
      display: flex;
      justify-content: space-between;
      margin-top: .2rem;
      .middleVideo:nth-child(2n){
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .middleVideo{
      border-bottom: 1px solid #eee;
      padding-bottom: .2rem;
      width: 1.875rem;
      .img{
        width: 1.665rem;
        height: 1.11rem;
        &>img{
          width: 100%;
          height: 1.11rem;
        }
        position: relative;
        .imgText{
          padding: .06rem .075rem;
          position: absolute;
          right: .1rem;
          bottom: .1rem;
          background: rgba(0,0,0,.6);
          border-radius: 2px;
          display: flex;
          align-items: center;
          img{
            width: .11rem;
            height: .11rem;
            margin-right: .04rem;
          }
          .during{
            font-size: .13rem;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
      &>div{
        width: 1.665rem;
      }
    }
    }

    .title{
      font-size: .17rem;
      font-weight: bold;
      color: #333333;
      line-height: .26rem;
      margin-top: .145rem;
      margin-bottom: .105rem;
    }
    .createTime{
      font-size: .13rem;
      font-weight: 500;
      color: #999999;
    }
  }
</style>
