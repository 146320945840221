<template>
  <div class="footer">
    <div v-for="(item,index) in footerList" :key="index" class="item" @click="clickSelected(index)">
      <img class="img" :src="selected===index?item.selectedImg:item.img">
      <div :class="selected===index?'selected text':'text'">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      footerList: [
        { name: '首页', selectedImg: require(`@/assets/template/${this.$store.getters.moduleName}/footer/01.png`), img: require(`@/assets/template/${this.$store.getters.moduleName}/footer/06.png`), path: '/home' },
        { name: '菜单导航', selectedImg: require(`@/assets/template/${this.$store.getters.moduleName}/footer/02.png`), img: require(`@/assets/template/${this.$store.getters.moduleName}/footer/07.png`), path: '/menu' },
        { name: '办事服务', selectedImg: require(`@/assets/template/${this.$store.getters.moduleName}/footer/03.png`), img: require(`@/assets/template/${this.$store.getters.moduleName}/footer/08.png`), path: '/server' },
        { name: '民意反馈', selectedImg: require(`@/assets/template/${this.$store.getters.moduleName}/footer/04.png`), img: require(`@/assets/template/${this.$store.getters.moduleName}/footer/09.png`), path: '/idea' },
        { name: '我的', selectedImg: require(`@/assets/template/${this.$store.getters.moduleName}/footer/05.png`), img: require(`@/assets/template/${this.$store.getters.moduleName}/footer/10.png`), path: '/mine' }
      ],
      selected: 0
    }
  },
  watch: {
    $route() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  methods: {
    clickSelected(index) {
      if (index === this.selected) return
      this.selected = index
      this.$go(this.footerList[index].path)
    },
    init() {
      this.param = this.$getParams()
      this.getSelected()
    },
    getSelected() {
      const path = this.$route.path
      for (const index in this.footerList) {
        const item = this.footerList[index]
        if (item.path == path) {
          this.selected = index * 1
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer{
    height: .50rem;
    border-top: 1px solid #EEE;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .item{
      display: flex;
      flex-direction: column;
      align-items: center;
      .img{
        width: .22rem;
        margin-bottom: .02rem;
      }
      .text{
        font-size: .12rem;
        font-weight: bold;
        color: #333333;
      }
      .selected{
        color:var(--theme)!important;
      }
    }

  }
</style>
