<template>
  <div class="Content">
    <div v-if="dataInfo.length" class="content">
      <!-- 1文章、2视频、3公告、4三务公开、5精准扶贫  145一类 -->
      <div>
        <div v-for="(item,index) in dataInfo" :key="index" class="content-item" @click="goUrl(item.id)">
          <div class="content-text">
            <div
              v-for="(item2,index2) in contentData"
              :key="index2"
              :class="item2.class || []"
              :style="item2.style || {}"
              class="colcenter"
            >
              {{ item2.label?item2.label + '：':'' }}<span :class="classCheck(item,item2)">{{ item[item2.id] }}</span>
            </div>
          </div>
          <div class="goImgOut">
            <img class="goImg" :src="require('@/assets/right.png')">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentData: {
      type: Array,
      default: () => [

      ]
    },
    limit: {
      type: Number,
      default: 4
    },
    detailUrl: {
      type: String,
      default: ''
    },
    initQuery: {
      type: Object,
      default: () => { return {} }
    },
    dataInfo: {
      type: Array,
      default: () => { return [] }
    }
  },
  data() {
    return {
      routeName: this.$route.path.split('/').slice(-1)[0]
    }
  },
  methods: {
    goUrl(id) {
      this.$router.push({ path: `/mine/${this.routeName}/detail`, query: { id }})
    },
    classCheck(item, item2) {
      if (item2.textClass) {
        return item2.textClass(item[item2.id], this.routeName)
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.Content {
  background: #f2f3f5;
  padding: 0rem .16rem .15rem .16rem;
  .content {

    .content-item {
      display: flex;
      background: #fff;
      padding: .16rem .15rem;
      margin-top: .15rem;
      border-radius: 2px;
      position: relative;
      .goImgOut{
        position: absolute;
        top: .60rem;
        bottom: 0;
        right: .15rem;
        display: flex;
        align-items: center;
      }

      .content-text {
        flex: 1;
        overflow: hidden;
        div{
          line-height: .32rem;
          font-size: .16rem;
          color: #666666;
        }
      }
    }
  }
  .colcenter{
    display: flex;
    align-items: center;
  }

}

.titleClass{
    font-size: .16rem!important;
    font-weight: bold;
    color: #333333!important;
    &::after{
      height: 1px;
      background: #EEEEEE;
      display: block;
      content: "";
      margin: .12rem 0;
    }
  }
.waitDiv,.cancelDiv,.greenDiv,.redDiv{ //待审批的框
  width: .54rem!important;
  height: .23rem!important;
  font-size: .14rem!important;
  line-height: .23rem!important;
}
</style>
