<template>

  <div class="ListOut">
    <div v-if="!list.length && finished && !loading" class="empty">
      <img :src="require('@/assets/searchEmpty.png')">
      <div class="text">暂无搜索结果</div>
    </div>

    <van-pull-refresh v-show="!(list.length == 0 && finished)" v-model="refreshing" class="List" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        error-text="请求失败，点击重新加载"
        :error.sync="error"
        class="vanList"
        @load="onLoad"
      >
        <itemContent :selected="$parent.selected*1" :data-list="list" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh } from 'vant'
import itemContent from './itemContent.vue'
export default {
  components: { 'van-pull-refresh': PullRefresh, 'van-list': List, itemContent },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      page: 1
    }
  },
  computed: {
  },
  watch: {
    $route() {
      this.init()
      this.$nextTick(() => {
        this.onLoad()
      })
    }
  },
  created() {
    this.init()
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }

      this.getData()
    },
    onRefresh() {
      // 清空列表数据
      this.list = []
      this.page = 1
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    getData() {
      this.loading = true
      this.$store.dispatch('main/ServicesGetServiceList', { page: this.page, limit: 10, category: this.$parent.selected * 1 + 1 }).then(res => {
        if (res.code === 0) {
          if (!res.data.length) {
            this.finished = true
          } else {
            if (this.list.length) {
              this.list.push(...res.data)
            } else {
              this.list = res.data
            }
            this.page = this.page * 1 + 1
          }
          if (res.data.length < 10) {
            this.finished = true
          }
        } else {
          this.error = true
        }
        this.loading = false
      })
    },
    init() { // 切换标签后初始化数据
      const parent = this.$parent
      this.param = this.$getParams()
      this.page = this.param.page || 1
      parent.selected = this.param.selected * 1 || 0
    }
  }
}
</script>

<style lang="scss" scoped>
.ListOut{
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  padding: .15rem .16rem 0 .16rem;
}
  .empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    img{
      width:1.205rem;
      height: 1.24rem;
    }
    .text{
      font-size: .13rem;
      font-weight: 500;
      color: #999999;
      margin-top: .15rem;
    }
  }
  .List{
    overflow-y: auto;
    flex: 1;
  }
</style>
