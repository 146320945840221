<template>
  <detail :reply-data-list="replyDataList" :detail-data="detailData" />
</template>

<script>
import detail from '@/components/contentComponent/detail.vue'
export default {
  components: { detail },
  data() {
    return {
      detailData: [
        { id: '', label: '', value: '困难帮扶申请', type: 'title' },
        { id: 'name', label: '姓名' },
        { id: 'lv', label: '困难程度' },
        { id: 'tel', label: '联系电话' },
        { id: 'idCardNo', label: '身份证' },
        { id: 'address', label: '家庭地址' },
        { id: 'accountName', label: '提交人' },
        { id: 'createTime', label: '提交日期' },
        { id: 'remark', label: '家庭情况', type: 'rich' },
        { id: 'strStatus', label: '状态', textColor: { '待审核': '#FF8610', '通过': '#2EBC74', '不通过': 'var(--theme)', '已取消': '#999999' }, type: 'status' }
      ],
      replyDataList: [
        { id: 'strStatus', label: '状态', textColor: { '待审核': '#FF8610', '通过': '#2EBC74', '不通过': 'var(--theme)', '已取消': '#999999' }, type: 'status' },
        { id: 'approveContext', label: '备注' },
        { id: 'helper', label: '帮扶人' }
      ],
      methodPath: { // 接口必传
        getInfoFromId: 'personal/DifficultyPeopleGetDiffPersonInfo', // 根据id获取详情的接口
        del: 'personal/DifficultyPeopleDiffPersonDel', // 删除接口
        cancel: 'personal/DifficultyPeopleUnPerson' // 取消接口
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
