<template>
  <div class="myComponent2">
    <el-upload
      ref="upload"
      class="avatar-uploader"
      :action="afterUrl"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      name="imgFile"
    >
      <div v-if="!loading" class="picture-card">
        <div class="father">
          <img :src="imgUrl || require('@/assets/top.png')" class="avatar">
          <!-- <div class="hovers " @click.stop>
            <i class="el-icon-delete del" @click="clickRemove" />
          </div> -->
        </div>
      </div>
      <div v-else v-loading="loading" class="avatar border" />

      <div class="showbutton">
        <div
          slot="trigger"
          class="uploadButton center"
          size="small"
          type="primary"
        >上传头像</div>
        <div class="uploadtext" @click.stop>
          <div class="uploadtext-1">
            建议尺寸{{ size ? size : '1920*450' }}像素
          </div>
          <div class="uploadtext-2">
            支持JPG/JPEG/BMP/GIF/PNG/WEBP格式，大小不超过8M
          </div>
        </div>
      </div>
    </el-upload>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: null
    },
    itemInfoKey: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      afterUrl:
        window.location.protocol +
        '//10000.165183.com/UserControls/upload_json.ashx?dir=default',
      loading: false
    }
  },

  mounted() {
  },

  methods: {
    handleAvatarSuccess(res, file) {
      if (!res.url) {
        this.$message.error(res.message)
      }
      this.$emit('handleAvatarSuccess', res.url, this.itemInfoKey)
      this.loading = false
    },
    clickRemove() {
      this.$emit('handleAvatarSuccess', '', this.itemInfoKey)
    },
    beforeAvatarUpload(file) {
      const typeList = [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/bmp',
        'image/gif',
        'image/webp'
      ]
      const isOk = typeList.includes(file.type)
      const isLt2M = file.size / 1024 < 8000
      if (!this.file) {
        if (!isOk) {
          this.$message.error('上传头像图片只能是 JPG/png 格式!')
          return false
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过500k!')
          return false
        }
        this.loading = true

        return isOk && isLt2M
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.myComponent2 {
  ::v-deep {
    .avatar-uploader {
      position: relative;
    }
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }
    .avatar {
      width: .73rem;
      height: .73rem;
    }
    .avatar-uploader{
      padding-bottom: .5rem!important;

    }
  }
}

.showbutton {
  //空值上传图片的按钮
  position: absolute;
  top: 0;
  left: .86rem;
  display: flex;
  flex-direction: column;
  width: .90rem;
}
.uploadtext-1 {
  width: max-content;
  font-size: .13rem;
  font-weight: 500;
  color: #999999;
  height: .2rem;
}
.uploadtext-2 {
  width: max-content;
  font-size: .13rem;
  font-weight: 500;
  color: #999999;
  height: .2rem;
}
.father {
  position: relative;
  width: .73rem;
  height: .73rem;
  .hovers {
    display: none;
  }
}
.father:hover {
  position: relative;
  .hovers {
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    .del {
      transform: scale(2);
      color: #000;
      cursor: pointer;
    }
  }
}
.uploadButton{
  width: .90rem;
  height: .30rem;
  background: #FFFFFF;
  border: 1px solid var(--theme);
  border-radius: 2px;
  font-size: .13rem;
  font-weight: 500;
  color: var(--theme);
  margin-top: .215rem;
}

.uploadtext{
  position: absolute;
  left: -.85rem;
  top: .7rem;
}
</style>
