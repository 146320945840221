<template>
  <div class="detail">
    <div class="question">
      <div class="title">{{ dataInfo.title }}</div>
      <div class="type redDiv">类型:{{ dataInfo.type }}</div>
      <div class="createTime">提交时间：{{ dataInfo.createTime }}</div>
      <div class="richOut">
        <div class="text">详情：</div>
        <div class="rich" v-html="$setContent(dataInfo.context)" />
      </div>
    </div>
    <div v-if="dataInfo.status!=1" class="reply">
      <div class="title">答复详情</div>
      <div class="pb">答复单位：{{ dataInfo.replyPbName }}</div>
      <div class="replayTime">答复时间：{{ dataInfo.replyTime }}</div>
      <div class="line" />
      <div class="text">答复详情：</div>
      <div class="rich" v-html="$setContent(dataInfo.replyContext)" />
    </div>
    <div v-else class="empty">
      <img :src="require('@/assets/searchEmpty.png')">
      <div>暂无回复</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataInfo: {}
    }
  },

  mounted() {

  },
  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      this.param = this.$getParams()
      this.$store
        .dispatch('home/ConsultingGetConsInfo', { id: this.param.id })
        .then((res) => {
          if (res.code === 0) {
            this.dataInfo = res.data
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail{
    background: #f2f3f5;
    .question{
      background: #fff;
      padding: .15rem .17rem;
      .title{
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
        line-height: .32rem;
        margin-bottom: .15rem;
      }
      .type{
        width: 1.01rem;
        height: .24rem;
        line-height: .24rem;
        border: 1px solid var(--theme);
        margin-bottom: .15rem;
      }
      .createTime{
        font-size: .15rem;
        font-weight: 500;
        color: #999999;
        margin-bottom: .35rem;
      }
      .richOut{
        display: flex;
        .text{
          width: max-content;
        }
        .rich{
          flex: 1;
        }
      }
    }
    .reply{
      background: #f2f3f5;
      padding: .26rem .17rem;
      .title{
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
        margin-bottom: .3rem;
        text-align: center;
      }
      .pb{
        font-size: .15rem;
        font-weight: 500;
        color: #333333;
        margin-bottom: .17rem;
      }
      .replayTime{
        font-size: .15rem;
        font-weight: 500;
        color: #333333;
        margin-bottom: .2rem;
      }
      .line{
        height: 1px;
        background: #DDDDDD;
        margin-bottom: .15rem;
      }
      .text{
        font-size: .16rem;
        font-weight: 500;
        color: var(--theme);
        margin-bottom: .15rem;
      }
    }
    .empty{
      height: 2.98rem;
      background: #f2f3f5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img{
        width: 1.21rem;
        margin-bottom: .14rem;
      }
      div{
        font-size: .16rem;
        font-weight: 500;
        color: #999999;
      }
    }
  }
</style>
