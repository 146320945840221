<template>
  <detail :reply-data-list="replyDataList" :detail-data="detailData" />
</template>

<script>
import detail from '@/components/contentComponent/detail.vue'
export default {
  components: { detail },
  data() {
    return {
      detailData: [
        { id: 'title', label: '', type: 'title' },
        { id: 'accountName', label: '提交人' },
        { id: 'createTime', label: '提交时间' },
        { id: 'type', label: '类型' },
        { id: 'strStatus', label: '状态', textColor: { '待处理': '#FF3F3F', '已处理': '#42C07E' }, type: 'status' },
        { id: 'context', label: '问题详情', type: 'rich' }
      ],
      replyDataList: [
        { id: 'replyContext', label: '回复' },
        { id: 'replyTime', label: '回复时间' }
      ],
      methodPath: { // 接口必传
        getInfoFromId: 'personal/ConsultingConsultingInfo', // 根据id获取详情的接口
        del: 'personal/ConsultingConsultingDel' // 删除接口
      }
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
