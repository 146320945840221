<template>
  <div class="navTop center">
    <div v-if="backUrl" style="height:0.435rem" class="center back" @click="$goBack()">
      <img class="backImg" :src="require(`@/assets/back-black.png`)">
    </div>

    <van-search
      ref="search"
      v-model="searchText"
      placeholder="请输入你要搜索的内容"
      autofocus
      @search="onSearch"
    />

    <div class="searchText" @click="onSearch">
      搜索
    </div>

  </div>
</template>

<script>
import { Search } from 'vant'
export default {
  components: { 'van-search': Search },
  data() {
    return {
      searchText: '',
      total: 0,
      page: 1,
      param: {}
    }
  },
  computed: {
    backUrl() {
      let path = this.$route.path
      path = path.replace(/^\//, '')
      if (path.split('/').length <= 1) return false
      else return '/' + path.split('/').slice(0, -1).join('/')
    }
  },
  watch: {
    $route() {
      this.init()
      this.$nextTick(() => {
        this.$parent.$refs.searchcontent.onLoad()
      })
    }
  },
  mounted() {
  },
  created() {
    this.init()
  },
  methods: {
    onSearch() {
      if (this.searchText.trim() === this.$parent.text) return
      this.$parent.$refs.searchcontent.reSet()
      console.log('1')
      this.$changeParam(
        {
          searchText: this.searchText.trim(),
          page: 1,
          selected: this.$parent.selected
        }
      )
    },
    init() {
      const parent = this.$parent

      this.param = this.$getParams()
      this.param.searchText = (this.param.searchText || '').trim()
      this.searchText = this.param.searchText || ''
      this.page = this.param.page || 1

      parent.selected = this.param.selected * 1 || 0
      parent.text = (this.param.searchText || '').trim()
    }
  }

}
</script>

<style lang="scss" scoped>
  ::v-deep{
    .van-search{
      width: 2.65rem;
      padding: unset!important;
      background: unset!important;
      .van-search__content{
        background-color: #fff!important;
        border-radius: 999px;
      }
    }
  }
  .navTop{
    background: #ededed;
    padding: .045rem .165rem .095rem .165rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .back{
      .backImg{
        width: .20rem;
      }
    }
    .searchText{
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }

  }
</style>
