import { Toast } from 'vant'
import wx from 'weixin-js-sdk'

// var firstUrl = ''
const originUrl = window.location.href
function checkIsios() {
  var u = navigator.userAgent
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
  if (isiOS) return true
  else return false
}
export default async function wxInitConfig(shareInfo) {
  try {
    const isIos = checkIsios() // 是否ios
    let url = location.href.split('#')[0]
    // if (!firstUrl) {
    //   firstUrl = url
    // }
    if (isIos) { // ios
      url = originUrl
    }

    const res = await this.$store.dispatch('main/SitewxShare', { url })
    const jsApiList = [
      'onMenuShareTimeline',
      'onMenuShareAppMessage',
      'onMenuShareQZone',
      'onMenuShareQQ',
      'onMenuShareWeibo'
    ]
    const { title, desc, imgUrl } = shareInfo

    const doFunc = () => {
      jsApiList.forEach(item => {
        wx[item]({
          title, desc, imgUrl, link: url
        })
      })
    }

    if (res.code === 0) {
      wx.config({
        ...res.data
      })
      // wx.error(err => {
      //   alert('wx config fail:originUrl:' + originUrl + 'width:' + window.screen.width + ';height:' + window.screen.height + JSON.stringify(err) + ';;' + url)
      // })
      wx.ready(function() {
        doFunc()
      })
      if (isIos) {
        try {
          doFunc()
        } catch (error) {
          console.log(error)
        }
      }
    }
  } catch (error) {
    Toast.fail(error)
  }
}
