<template>
  <div class="setting">
    <div @click="$go('/mine/setting/UpdatePwd')">修改密码</div>
    <div @click="$showNotice(()=>logout(),'确定退出登录?')">退出</div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {
    logout() {
      this.$store.commit('app/LOGIN_OUT')
      this.$go('/')
    }
  }
}
</script>

<style lang="scss" scoped>
  .setting{
    background: #f2f3f5;
    div{
      height: .60rem;
      background: #FFFFFF;
      line-height: .6rem;
      padding-left: .16rem;
      margin-top: .15rem;
      font-size: .16rem;
      font-weight: 500;
      color: #333333;
    }
  }
</style>
