<template>
  <div class="detail">
    <div v-if="dataDist.video" class="video">
      <video :controls="true" :autoplay="false" :src="$beforeUrl(dataDist.video)" :poster="$beforeUrl(dataDist.videoPoster)" />
    </div>
    <div class="content">
      <div class="title twoLine" :style="{marginTop:dataDist.video?'.135rem':'.195rem'}">{{ dataDist.title }}</div>
      <div class="timeAndName">
        <div v-if="dataDist.createTime" class="createTime">发布时间：{{ dataDist.createTime.split(' ')[0] }}</div>
        <div class="creater">发布：{{ dataDist.createrName }}</div>
      </div>
      <div class="rich" v-html="$setContent(dataDist.context)" />
      <div class="abc" />
      <div class="timeAndName">
        <div class="createTime">来源：{{ dataDist.pbName }}</div>
        <div class="creater">浏览：{{ dataDist.pv }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import wxInitConfig from '@/utils/weixinJsSdk.js'
export default {
  data() {
    return {
      param: {},
      dataDist: {}
    }
  },

  mounted() {

  },
  created() {
    this.init()
  },

  methods: {
    init() {
      this.param = this.$getParams()

      this.$store.dispatch('main/HomeGetNewsInfo', { id: this.param.id }).then(res => {
        this.dataDist = res.data
        // this.$route.meta.backQuery = { id: res.data.categoryId, type: res.data.type } // 配置后退参数
        if (res.data.type == 2) {
          this.$store.commit('app/SET_ROUTENAME', '党建视频')
        } else {
          this.$store.commit('app/SET_ROUTENAME', '详情')
        }
        this.getShare({ title: res.data.shareTitle, desc: res.data.shareDesc, imgUrl: this.$beforeUrl(res.data.thumbnail || res.data.videoPoster) })
      })
    },
    getShare(info) {
      wxInitConfig.apply(this, [info])
    }
  }
}
</script>

<style lang="scss" scoped>

    .detail{
      padding-bottom: .04rem;
       .video{
      width: 3.75rem;
      height: 2.11rem;
        video{
          width: 100%;
          height: 100%;
        }
      }
      .content{
        width: 3.34rem;
        margin: auto;
         .title{
        font-size: .24rem;
        font-weight: bold;
        color: #333333;
        line-height: .36rem;
      }
      .timeAndName{
        margin-top: .18rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.29rem;
        .createTime{
          font-size: .13rem;
          font-weight: 500;
          color: #999999;
        }
        .creater{
          font-size: .13rem;
          font-weight: 500;
          color: #999999;
        }
      }
      }

    }

    .abc{
      height: 1px;
      background: #EEEEEE;
      display: block;
      margin-top:.20rem ;
      margin-bottom:.16rem ;
    }
</style>
