<template>
  <div class="first">
    <logo class="logos" />
    <div class="searchOut">
      <div class="search" @click="$go('/home/search')">
        <img class="searchIcon" :src="require('@/assets/template/first/home/search.png')">
        <div class="text">请输入搜索关键词…</div>
      </div>
      <div class="ooo">
        <img class="zb" :src="require('@/assets/template/first/home/zb.png')" @click="$go('/home/GetPbDem')">
        <div style="color:#fff;font-size:.08rem">支部风采</div>
      </div>
    </div>

    <!-- 轮播图 -->
    <lunbo />

    <!-- 中间八个图标 -->
    <middleEightIcon />

    <!-- 公告 -->
    <notice />

    <!-- 灰色的线 -->
    <div class="line" />

    <TextAndImg more-url="/menu/main?name=时政要闻&type=1" :param="{top:5,type:1}" text="时政要闻" />

    <div class="line" />

    <TextAndImg more-url="/menu/main?name=党建动态&type=1" :param="{top:5,type:4}" text="党建动态" />

    <div class="line" />

    <ads position="langBan" />

    <div class="line" />

    <TextAndImg more-url="/menu/main?name=专题报道&type=1" :param="{top:5,type:2}" text="专题报道" />

    <div class="line" />

    <!-- 视频 -->
    <videoComponent />

    <div class="line" />

    <ads position="center1Ban" />

    <div class="line" />

    <TextAndImg more-url="/menu/main?name=政策法规&type=1" :param="{top:5,type:3}" text="政策法规" />

    <div class="line" />

    <TextAndImg more-url="/menu/main?name=志愿者活动&type=1" :param="{top:5,type:14}" text="志愿者活动" />

    <div class="line" />

    <ads position="footBan" />

    <div class="line" />

    <sj />
  </div>
</template>

<script>
import lunbo from './components/lunbo.vue'
import middleEightIcon from './components/middleEightIcon.vue'
import notice from './components/notice.vue'
import TextAndImg from './components/content/index.vue'
import ads from './components/adImg.vue'
import videoComponent from './components/video.vue'
import sj from './components/sj.vue'
export default {
  components: { lunbo, middleEightIcon, notice, TextAndImg, ads, videoComponent, sj },
  data() {
    return {
    }
  },

  mounted() {

  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.first{
  background-image: url(../../../../assets/template/first/home/bg.png);
  background-size: 100vw;
  background-repeat: no-repeat;
  background-position: 0 0;
  padding-top: .045rem;

  .line{
    height: .13rem;
    background: #f2f3f5;
  }
  .logos{
    margin:.045rem auto .13rem auto;
    display: block;
  }
  .searchOut{
    position: relative;
    .search{
      width: 2.3rem;
      height: .40rem;
      background: rgba(0,0,0,.1);
      border-radius: 999px;
      display: flex;
      align-items: center;
      margin: auto;
      .searchIcon{
        width: .15rem;
        height: .155rem;
        margin-left: .15rem;
        margin-right: .095rem;
      }
      .text{
        font-size: .14rem;
        font-weight: 500;
        color: #FFFFFF;
        opacity: 0.5;
      }
    }

  }
}

.ooo{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
   position: absolute;
      right: .16rem;
      top: 0;
      .zb{
      width: .25rem;
      height: .25rem;

    }
}
</style>
