<template>
  <div class="detail">
    <div class="title">{{ info.title }}</div>
    <div class="nameAndTime">
      <div class="name">书记：{{ info.firstUser }}</div>
      <div class="time">书记：{{ info.createTime }}</div>
    </div>
    <div class="rich" v-html="$setContent(info.context)" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        'title': '23123',
        'createTime': '2021年10月12日',
        'firstUser': '张武',
        'context': '123134523'
      },
      param: {
        id: ''
      }
    }
  },
  created() {
    this.getInfo()
  },

  methods: {
    getInfo() {
      this.param = this.$getParams()
      this.$store.dispatch('main/SecretaryGetFirstNewsInfo', { id: this.param.id }).then(res => {
        if (res.code === 0) {
          this.info = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail{
    padding: .2rem .17rem;
    .title{
      font-size: .24rem;
      font-weight: bold;
      color: #333333;
    }
    .nameAndTime{
      display: flex;
      justify-content: space-between;
      font-size: .13rem;
      font-weight: 500;
      color: #999999;
      margin-top: .2rem;
      margin-bottom: .31rem;
    }
  }

</style>
