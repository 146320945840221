
const routes = [
  {
    path: '/idea',
    component: () => import('@/views/idea'),
    meta: { name: '民意反馈' }
  },
  {
    path: '/idea/Readily',
    component: () => import('@/views/idea/sonPage/Readily'),
    meta: { name: '群众随手拍', requireLogin: true }
  },
  {
    path: '/idea/AddProposal',
    component: () => import('@/views/idea/sonPage/AddProposal'),
    meta: { name: '留言建议', requireLogin: true }
  },
  {
    path: '/idea/DemandCreate',
    component: () => import('@/views/idea/sonPage/DemandCreate'),
    meta: { name: '需求对接', requireLogin: true }
  },
  {
    path: '/idea/CreateDiff',
    component: () => import('@/views/idea/sonPage/CreateDiff'),
    meta: { name: '困难帮扶', requireLogin: true }
  },
  {
    path: '/idea/ConsultingList',
    component: () => import('@/views/idea/sonPage/ConsultingList'),
    meta: { name: '' }
  },
  {
    path: '/idea/ConsultingCreate',
    component: () => import('@/views/idea/sonPage/ConsultingCreate'),
    meta: { name: '发表内容', requireLogin: true }
  },
  {
    path: '/idea/ConsultingList/detail',
    component: () => import('@/views/idea/sonPage/ConsultingListDetail'),
    meta: { name: '详情' }
  }
]

export default routes
