<template>
  <div class="main">
    <van-tabs v-model="active" color="var(--theme)" title-active-color="var(--theme)" title-inactive-color="#999" @change="change">
      <van-tab v-for="(item,index) in menuList" :key="index" :title="item.name" title-style="font-size: .15rem;font-weight: 500;" />
    </van-tabs>

    <contentComponent ref="sonPage" class="content" />
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant'
import contentComponent from './components/content.vue'
export default {
  components: { 'van-tab': Tab, 'van-tabs': Tabs, contentComponent },
  data() {
    return {
      active: 0,
      menuList: [],
      param: {}
    }
  },
  watch: {
    async $route() {
      await this.$nextTick()
      this.$refs.sonPage.reset()
      this.init()
    }
  },

  mounted() {

  },
  created() {
    this.init()
  },

  methods: {
    async init() {
      if (this.param.type === this.$getParams().type) {
        this.param = this.$getParams()
        this.getSonInit()
        this.$store.commit('app/SET_ROUTENAME', this.getNameForType())
      } else {
        this.param = this.$getParams()
        this.$store.commit('app/SET_ROUTENAME', this.getNameForType())
        await this.getNavList() // 获取头部导航
        this.getSonInit()
      }
    },
    async getSonInit() {
      this.getActiveForId()
      await this.$refs.sonPage.getInit()
      this.$nextTick(() => {
        this.$refs.sonPage.watchScroll()
      })
    },
    getNavList() {
      return new Promise((resolve, reject) => {
        if (this.param.type == 3) {
          this.menuList = [
            { name: '办事指南', id: 1 },
            { name: '公共服务', id: 2 }
          ]
          resolve()
        } else {
          this.$store.dispatch('main/NewsCategoryGetCategoryList', { type: this.param.type || 1 }).then(res => {
            if (res.code === 0) {
              this.menuList = res.data
              resolve()
            } else {
              reject()
            }
          })
        }
      })
    },
    getNameForType(type = (this.param.type || 1)) {
      if (type == 1) {
        return '新闻栏目'
      } else if (type == 2) {
        return '党建视频'
      } else if (type == 3) {
        return '办事服务'
      } else if (type == 4) {
        return '三务公开'
      } else if (type == 5) {
        return '困难帮扶'
      }
    },
    getActiveForId(id = (this.param.id)) {
      if (!id) {
        const name = this.param.name
        for (const index in this.menuList) {
          const item = this.menuList[index]
          if (item.name === name) {
            this.active = index * 1
            break
          }
        }
      } else {
        for (const index in this.menuList) {
          const item = this.menuList[index]
          if (item.id === id) {
            this.active = index * 1
            break
          }
        }
      }
    },
    change(v) {
      this.param.id = this.menuList[v].id
      this.$changeParam(this.param, true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .main{
    height: 100%;
    display: flex;
    flex-direction: column;
    .content{
      flex:1;
      overflow-y: auto;
    }
  }
</style>
