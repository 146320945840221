<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="没有更多了"
    error-text="请求失败，点击重新加载"
    :error.sync="error"
    class="List vanList"
    @load="onLoad"
  >
    <itemContent v-show="!error" :data-list="list" />
  </van-list>

</template>

<script>
import itemContent from './TextAndImg.vue'
import { List } from 'vant'
export default {
  components: {
    itemContent,
    'van-list': List
  },
  props: {
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      error: false,
      page: 1
    }
  },
  computed: {
    parent() {
      return this.$parent.$parent.$parent
    }
  },

  watch: {
    $route() {
      this.$nextTick(() => {
        this.onLoad()
      })
    }
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    onLoad() {
      this.getData()
    },
    onRefresh() {
      // 清空列表数据
      this.list = []
      this.page = 1
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
    },
    getData() {
      this.loading = true

      console.log('this.getId()', this.getId())
      this.$store.dispatch('main/NewsCategoryGetNewsData', { page: this.page, limit: 10, id: this.getId() }).then(res => {
        if (res.code === 0) {
          let data = res.data
          if (!data.length) {
            this.finished = true
          } else {
            data = this.addAds(this.page, data)
            if (this.list.length) {
              this.list.push(...data)
            } else {
              this.list = data
            }
            this.page = this.page * 1 + 1
          }
          if (data.length < 10) {
            this.finished = true
          }
        } else {
          this.error = true
        }
        this.loading = false
      })
    },
    getId() {
      const parent = this.parent
      // console.log(`parent.menuList[parent.active]`, parent.menuList[parent.active])
      return parent.menuList[parent.active].id
    },
    addAds(page, data) {
      if (page != 1) return data

      const { name } = this.parent.menuList[this.parent.active]
      let position
      if (name == '时政要闻') position = 'langBan'
      else if (name == '党建动态') position = 'center1Ban'
      else if (name == '专题报道') position = 'footBan'
      else return data
      if (data.length > 5) {
        data = [...data.slice(0, 5), position, ...data.slice(5)]
      } else {
        data.push(position)
      }
      return data
    }
  }
}
</script>

<style lang="scss" scoped>
  .ListOut{
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  padding: .15rem .16rem 0 .16rem;
}
  .empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    img{
      width:1.205rem;
      height: 1.24rem;
    }
    .text{
      font-size: .13rem;
      font-weight: 500;
      color: #999999;
      margin-top: .15rem;
    }
  }
  .List{
    overflow-y: auto;
    flex: 1;
    padding: .2rem .12rem;
  }
</style>
