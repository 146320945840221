<template>
  <div v-loading="loading">
    <component :is="components" v-if="colList.length" />
  </div>
</template>

<script>
export default {

  data() {
    return {
      components: (resolve) => require([`@/views/home/components/${this.$store.getters.moduleName}/index.vue`], resolve),
      colList: [],
      loading: true
    }
  },

  mounted() {

  },
  created() {
    this.getColId()
  },
  methods: {
    getColId() {
      this.loading = true
      this.$store.dispatch('home/HomeGetColum').then(res => {
        if (res.code === 0) {
          this.colList = res.data
          this.$store.state.app.colList = res.data
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
