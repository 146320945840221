<template>
  <div ref="contentOuts" v-loading="loading" class="contentOuts">
    <div class="content1">
      <itemContent v-if="$parent.param.type!='2'" :data-list="dataList" />
      <videoContent v-else :video-list="dataList" />
      <div v-if="page===-1&&!loading&&dataList.length > 0" class="noMore">--我是有底线的--</div>
    </div>
  </div>
</template>

<script>
import itemContent from '@/views/home/components/first/components/content/TextAndImg.vue'
import videoContent from './videoContent.vue'
export default {
  components: {
    itemContent, videoContent
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dataList: [],
      loading: true,
      offsetHeight: 0, // 该 div 的高度
      scrollHeight: 0, // 滚动条高度 + div的高度 1个px左右误差
      isReachBottom: false,
      page: 1
    }
  },

  mounted() {

  },
  created() {

  },

  methods: {
    reset() { // 回到初始
      this.dataList = []
      this.loading = true
      this.offsetHeight = 0
      this.scrollHeight = 0
      this.isReachBottom = false
      this.page = 1
    },
    getInit() {
      this.loading = true
      return new Promise((resolve, reject) => {
        const url = this.$parent.param.type == '2' ? 'main/NewsCategoryGetVideoData' : (this.$parent.param.type == '5' ? 'main/NewsCategoryGetDiffList' : 'main/NewsCategoryGetNewsData') // 党建视频与其他类别的地址不同
        const data = {}; const id = this.getId()
        if (this.$parent.param.type == 5) {
          data.categoryId = id
        }
        this.$store.dispatch(url, { limit: 10, id, page: this.page, ...data }).then((res) => {
          if (res.code === 0) {
            if (!res.data.length) { // 没有下一页
              this.page = -1
            } else {
              this.dataList.push(...res.data)
              this.page += 1
            }
            if (res.data.length < 10) {
              this.finished = true
            }

            resolve()
          } else {
            this.$message.error(res.msg || '请求失败,请刷新重试')
            reject()
          }
          setTimeout(() => {
            this.loading = false
          }, 500)
        })
      })
    },
    watchScroll() {
      // 获取元素
      const scrollview = this.$refs.contentOuts
      scrollview.addEventListener('scroll', this.scrollChange, true)
      this.scrollHeight = scrollview.scrollHeight - 100

      this.offsetHeight = Math.ceil(scrollview.getBoundingClientRect().height) // 该 div 的高度
    },
    async scrollChange(e) {
      const scrollTop = e.target.scrollTop // 当前滚动条的位置
      const currentHeight = scrollTop + this.offsetHeight
      // 保证向上回滚后达到临界值可以再次触底记录
      if (currentHeight < this.scrollHeight && this.isReachBottom) {
        this.isReachBottom = false
      }
      // 保证达到临界值后只记录一次触底
      if (this.isReachBottom) {
        return
      }
      if (currentHeight >= this.scrollHeight && this.page != -1) {
        this.isReachBottom = true
        await this.getInit()
        this.reSetScrollHeight() // 重置滚动条高度
      }
    },
    getId() {
      const parent = this.$parent
      return parent.menuList[parent.active].id
    },
    reSetScrollHeight() {
      const scrollview = this.$refs.contentOuts
      this.$nextTick(() => {
        this.scrollHeight = scrollview.scrollHeight - 100
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .contentOuts{
    padding: .25rem .16rem .20rem .16rem;
    ::v-deep{
      .el-loading-mask{
        position: fixed!important;
        // margin-top: calc( .435rem + 44px );
        margin-top: calc( .435rem );
      }
    }
    .noMore{
      font-size: .14rem;
      text-align: center;
      margin-top: .3rem;
    }
  }
</style>
