
const routes = [
  {
    path: '/server',
    component: () => import('@/views/server'),
    meta: { name: '办事服务' }
  },
  {
    path: '/server/map',
    component: () => import('@/views/map'),
    meta: { name: '地图导航' }
  }
]

export default routes
