import request from '@/utils/request'

const state = {
}

const mutations = {
}

const actions = {
  HomeGetSzNews({ state }, query) { // 首页-获取新闻
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetSzNews',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  HomeGetVideoNews({ state }, query) { // 首页-获取视频
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetVideoNews',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  HomeGetSiteAd({ state }, query) { // 广告位
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetSiteAd',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  HomeGetSearch({ state }, query) { // 首页搜索
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetSearch',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyGetReadilyList({ state }, query) { // 个人中心-随手拍列表
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/GetReadilyList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyGetReadilyInfo({ state }, query) { // 个人中心-获取随手拍详情
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/GetReadilyInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyDelReadily({ state }, data) { // 个人中心-删除随手拍
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/DelReadily',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyAddReadily({ state }, data) { // 首页-创建随手拍
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/AddReadily',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },
  ReadilyGetReadilyType({ state }, query) { // 随手拍类型-下拉框
    return new Promise((resolve, reject) => {
      request({
        url: '/Readily/GetReadilyType',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },

  PbDropDownPb({ state }, query) { // 党支部下拉框
    return new Promise((resolve, reject) => {
      request({
        url: '/Pb/DropDownPb',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },

  DemandDropDownDemand({ state }, query) { // 项目类型下拉框
    return new Promise((resolve, reject) => {
      request({
        url: '/Demand/DropDownDemand',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  DemandUpdate({ state }, data) { // 修改需求对接
    return new Promise((resolve, reject) => {
      request({
        url: '/Demand/Update',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },

  ConsultingGetConsultList({ state }, query) { // 首页-获取在线咨询，对话书记、监督举报、在线投诉、信息监督列表
    return new Promise((resolve, reject) => {
      request({
        url: '/Consulting/GetConsultList',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ConsultingGetConsInfo({ state }, query) { // 首页-获取在线咨询，对话书记、监督举报、在线投诉、信息监督信息
    return new Promise((resolve, reject) => {
      request({
        url: '/Consulting/GetConsInfo',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  ConsultingCreate({ state }, data) { // 首页-创建在线咨询，对话书记、监督举报、在线投诉、信息监督
    return new Promise((resolve, reject) => {
      request({
        url: '/Consulting/Create',
        method: 'post',
        data
      }).then(res => {
        resolve(res)
      })
    })
  },

  HomeGetNotice({ state }, query) { // 首页-公告
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetNotice',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  },
  HomeGetColum({ state }, query) { // 首页-公告
    return new Promise((resolve, reject) => {
      request({
        url: '/Home/GetColum',
        method: 'get',
        params: query
      }).then(res => {
        resolve(res)
      })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
