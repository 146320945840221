
const routes = [
  {
    path: '/mine',
    component: () => import('@/views/mine'),
    meta: { name: '我的', requireLogin: true }
  },
  {
    path: '/mine/Readily',
    component: () => import('@/views/mine/sonPage/Readily'),
    meta: { name: '群众随手拍', requireLogin: true }
  },
  {
    path: '/mine/setting',
    component: () => import('@/views/mine/sonPage/setting'),
    meta: { name: '设置', requireLogin: true }
  },
  {
    path: '/mine/setting/UpdatePwd',
    component: () => import('@/views/mine/sonPage/UpdatePwd'),
    meta: { name: '修改密码', requireLogin: true }
  },
  {
    path: '/mine/UpdateAccount',
    component: () => import('@/views/mine/sonPage/UpdateAccount'),
    meta: { name: '修改用户资料', requireLogin: true }
  }
]

const data = [
  { id: 'Readily', name: '群众随手拍' },
  { id: 'Proposal', name: '留言建议' },
  { id: 'Demand', name: '需求对接' },
  { id: 'DifficultyPeople', name: '困难帮扶' },
  { id: 'Online', name: '在线咨询' },
  { id: 'Dialogue', name: '对话书记' },
  { id: 'Supervise', name: '监督举报' },
  { id: 'Information', name: '信息监管' },
  { id: 'Complain', name: '在线投诉' }
]

data.forEach((item, index) => {
  routes.push(
    {
      path: `/mine/${item.id}`,
      component: (resolve) => require([`@/views/mine/sonPage/${item.id}/index.vue`], resolve),
      meta: { name: item.name, requireLogin: true }
    }
  )
  routes.push(
    {
      path: `/mine/${item.id}/detail`,
      component: (resolve) => require([`@/views/mine/sonPage/${item.id}Detail/index.vue`], resolve),
      meta: { name: item.name + '详情', requireLogin: true }
    }
  )
})

export default routes
