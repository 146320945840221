<template>
  <div class="video">
    <div v-if="newVideoList.length" class="topVideoOut">
      <div v-for="(item,index) in newVideoList" :key="index" class="topVideo" @click="$go(`/menu/main/detail?id=${item.id}`)">
        <div class="img">
          <img :src="$beforeUrl(item.videoPoster)">
          <div class="imgText">
            <img class="startIcon" :src="require('@/assets/template/first/home/startIcon.png')">
            <div class="during">{{ formatSeconds(item.during) }}</div>
          </div>
        </div>
        <div class="title twoLine">{{ item.title }}</div>
        <div class="createTime">发布时间：{{ item.createTime.split(' ')[0] }}</div>
      </div>
    </div>
    <div v-else-if="!$parent.loading" style="flex: 1; font-size: 1.00rem" class="colCenter it">
      <img :src="require('@/assets/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      newVideoList: []
    }
  },
  watch: {
    videoList: {
      deep: true,
      handler() {
        this.addDuration()
      }
    }
  },

  created() {
    this.addDuration()
  },

  methods: {
    formatSeconds(value) {
      if ((value + '').split('.').length > 2) return
      if (!value) return

      var theTime = parseInt(value)// 秒
      var theTime1 = 0// 分
      var theTime2 = 0// 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
        }
      }

      var result = '' + parseInt(theTime)// 秒
      if (theTime < 10 > 0) {
        result = '0' + parseInt(theTime)// 秒
      } else {
        result = '' + parseInt(theTime)// 秒
      }

      if (theTime1 < 10 > 0) {
        result = '0' + parseInt(theTime1) + ':' + result// 分，不足两位数，首位补充0，
      } else {
        result = '' + parseInt(theTime1) + ':' + result// 分
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + ':' + result// 时
      }
      return result
    },
    addDuration() {
      this.newVideoList = JSON.parse(JSON.stringify(this.videoList))
      this.newVideoList.map(item => {
        const audio = new Audio(this.$beforeUrl(item.video))
        item.during = ''
        audio.addEventListener('loadedmetadata', () => {
          item.during = audio.duration
          this.$forceUpdate()
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .video{
    .topVideo{
      border-bottom: 1px solid #eee;
      padding-bottom: .2rem;
      margin-bottom: .2rem;
      .img{
        width: 100%;
        height: 2.285rem;
        &>img{
          width: 100%;
          height: 2.285rem;
        }
        position: relative;
        .imgText{
          padding: .06rem .075rem;
          position: absolute;
          right: .1rem;
          bottom: .1rem;
          background: rgba(0,0,0,.6);
          border-radius: 2px;
          display: flex;
          align-items: center;
          img{
            width: .11rem;
            height: .11rem;
            margin-right: .04rem;
          }
          .during{
            font-size: .13rem;
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }
    }

    .title{
      font-size: .17rem;
      font-weight: bold;
      color: #333333;
      line-height: .26rem;
      margin-top: .145rem;
      margin-bottom: .105rem;
    }
    .createTime{
      font-size: .13rem;
      font-weight: 500;
      color: #999999;
    }
  }
</style>
