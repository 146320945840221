<template>
  <div class="myComponent">
    <div v-if="itemInfo" class="itemInfo">
      <el-form ref="itemInfo" :model="itemInfo" :rules="rules" class="demo-ruleForm">

        <div v-for="(item,index) in formType" :key="index">
          <!-- 图片 -->
          <div v-if="formType[index].type === 'img'" class="myComponent-item">
            <div class="item-second">
              <el-form-item class="item-second" :label="formType[index].label" :prop="index.toString()">
                <uploadOne
                  :file="formType[index].file"
                  :item-info-key="index"
                  :size="formType[index].size"
                  :show-button="true"
                  :img-url="$beforeUrl(itemInfo[index])"
                  @handleAvatarSuccess="handleAvatarSuccess"
                />
              </el-form-item>
            </div>
          </div>
          <div v-else-if="formType[index].type === 'select'" class="myComponent-item">
            <div class="item-second">
              <el-form-item class="item-second" :label="formType[index].label" :prop="index.toString()">
                <van-action-sheet
                  v-if="selectedInfo[index] === false || selectedInfo[index]"
                  v-model="selectedInfo[index]"
                  :actions="formType[index].options"
                  cancel-text="取消"
                  close-on-click-action
                  @cancel="selectedInfo[index]=false"
                  @select="(item)=>onSelect(item,index,formType[index].valUseName)"
                />
                <div class="selected" @click="showSelected(index)">
                  <div class="text" :style="{color:itemInfo[index]?'':'#999'}">{{ getName(index,formType[index].valUseName) || '请选择' }}</div>
                  <img class="rightImg" :src="require('@/assets/right.png')">
                </div>
              </el-form-item>
            </div>
          </div>

          <!-- 单选框 -->
          <div v-else-if="formType[index].type === 'deform'" class="myComponent-item" style="margin-bottom:.30rem">
            <label class="item-title">{{ formType[index].label }}</label>
            <div v-for="(item2,index2) in formType[index].data" :key="index2" style="margin-right:.10rem">
              <el-radio v-model="itemInfo[index]" :disabled="formType[index].disabled" :label="index2">{{ formType[index].data[index2] }}</el-radio>
            </div>
          </div>

          <!-- 富文本 -->
          <div v-else-if="formType[index].type === 'rich'" style="margin-bottom:.30rem" :style="formType[index].customStyle ? formType[index].customStyle : {}">
            <div class="myComponent-item ccc">
              <el-form-item class="item-second" :label="formType[index].label || '内容'" :prop="index.toString()">
                <rich :ref="index" style="flex:1" />
              </el-form-item>
            </div>
            {{ $nextTick(()=>{
              setRichText(index)
            }) }}
          </div>
          <!-- 时间选择器 -->
          <div
            v-else-if="formType[index].type === 'datePicker'"
            class="myComponent-item"
          >

            <el-form-item class="item-second" :label="formType[index].label" :prop="index.toString()">
              <el-date-picker
                v-model="itemInfo[index]"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              />
            </el-form-item>

          </div>
          <!-- 隐藏 -->
          <div v-else-if="formType[index].type === 'hidden'" style="display:none" />
          <!-- 自定义组件 -->
          <div v-else-if="formType[index].type === 'custom'">
            <component
              :is="formType[index].component"
              :dialog-title="dialogTitle"
              :item-info="itemInfo"
              :value="itemInfo[index]"
              :data="formType[index].data"
              @changeItemInfo="changeItemInfo"
              @customChange="function(){customChange(index,...arguments)}"
              @onChange="(e)=>{itemInfo[index]=e}"
            />
          </div>

          <!-- textarea -->
          <div v-else-if="formType[index].type === 'textarea'" class="myComponent-item">
            <div class="item-second">
              <el-form-item class="item-second" :label="formType[index].label" :prop="index.toString()">
                <el-input v-model="itemInfo[index]" type="textarea" :disabled="formType[index].disabled" :maxlength="formType[index].maxLength" class="input" :placeholder="formType[index].placeholder || '请输入'" />
                <div v-if="formType[index].afterDiv" :style="formType[index].afterDiv.customStyle?formType[index].afterDiv.customStyle:{}">{{ formType[index].afterDiv.text }}</div>
              </el-form-item>
            </div>
          </div>

          <!-- 正整数 -->
          <div v-else-if="formType[index].type === 'int'" class="myComponent-item">
            <div class="item-second">
              <el-form-item class="item-second" :label="formType[index].label" :prop="index.toString()">
                <el-input v-model="itemInfo[index]" :disabled="formType[index].disabled" :maxlength="formType[index].maxLength" class="input" :placeholder="formType[index].placeholder || `请输入${formType[index].label}`" @input="(v)=>intInput(v,index)" />
                <div v-if="formType[index].afterDiv" class="afterDiv" :style="formType[index].afterDiv.customStyle?formType[index].afterDiv.customStyle:{}">{{ formType[index].afterDiv.text }}</div>
              </el-form-item>
            </div>
          </div>

          <!-- 默认输入框 -->
          <div v-else class="myComponent-item">
            <div class="item-second">
              <el-form-item class="item-second" :label="formType[index].label" :prop="index.toString()">
                <el-input v-model="itemInfo[index]" :type="formType[index].isPassword?'password':(formType[index].type ? formType[index].type : 'text')" :disabled="formType[index].disabled" :maxlength="formType[index].maxLength" class="input" :placeholder="formType[index].placeholder || `请输入${formType[index].label}`" />
                <div v-if="formType[index].afterDiv" class="afterDiv" :style="formType[index].afterDiv.customStyle?formType[index].afterDiv.customStyle:{}">{{ formType[index].afterDiv.text }}</div>
              </el-form-item>
            </div>
          </div>

        </div>
        <div class="myComponent-item" style="margin-top:.4rem">
          <el-form-item class="item-second" label="">
            <el-button :loading="loading" class="dialog-footer-button" type="primary" @click="clickSubmit('itemInfo')">提交</el-button>
          </el-form-item>

        </div>
      </el-form>

    </div>

  </div>
</template>

<script>
import uploadOne from '@/components/uploadOne/index'
import rich from '@/views/components/editor.vue'
import { ActionSheet } from 'vant'
export default {
  components: { uploadOne, rich, 'van-action-sheet': ActionSheet },
  props: {
    row: {
      type: Object,
      default: () => { return {} }
    },
    methodPath: {
      type: Object,
      default: null
    },
    formType: {
      type: Object,
      default: null
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    // width: { // 窗口宽度 需带单位 该变量用在父组件,这里仅供参考
    //   type: String,
    //   default: ''
    // },
    height: { // 窗口高度 需带单位
      type: String,
      default: ''
    }
  },
  data() {
    return {
      itemInfo: undefined,
      rules: null,
      copyRow: '',
      loading: false,
      show: false,
      selectedInfo: {}
    }
  },
  watch: {
    row(v) {
      if (this.row) {
        this.copyRow = this.row
      }
      this.initEdit()
    }
  },
  mounted() {
    if (this.row) {
      this.copyRow = this.row
    }
    this.initEdit()
  },
  created() {
    this.initItemInfo()
    this.initRules()
    this.setSelectedInfo()
  },

  methods: {
    showSelected(key) {
      this.selectedInfo[key] = true
    },
    onSelect(item, key, valUseName) {
      if (valUseName) { this.itemInfo[key] = item.name } else { this.itemInfo[key] = item.id }
    },
    formatJsonText(copy) {
      if (copy.jsonText) {
        copy.jsonText = JSON.stringify(copy.jsonText)
      }
    },
    handleAvatarSuccess(file, itemInfoKey) {
      this.itemInfo[itemInfoKey] = file
    },
    clickSubmit(formName) {
      this.isRich() // 处理富文本
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.itemInfo.id) { // 编辑
            const copy = JSON.parse(JSON.stringify(this.itemInfo))
            if (copy.jsonText) {
              copy.jsonText = JSON.stringify(copy.jsonText)
            }
            this.$store.dispatch(this.methodPath.edit, copy).then(res => {
              if (res.code === 0) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                this.$emit('reFresh', 'edit')
              } else {
                return
              }
            }).finally(res => {
              this.loading = false
            })
          } else { // 创建
            const copy = JSON.parse(JSON.stringify(this.itemInfo))
            if (copy.jsonText) {
              copy.jsonText = JSON.stringify(copy.jsonText)
            }
            delete copy.id
            this.$store.dispatch(this.methodPath.create, copy).then(res => {
              this.loading = false
              if (res.code === 0) {
                this.$message({
                  message: '提交成功',
                  type: 'success'
                })
                this.$emit('reFresh', 'create')
              } else {
                return
              }
            }).finally(res => {
              this.loading = false
            })
          }
        } else {
          return false
        }
      })
    },
    clear() { // 清空表单
      this.initItemInfo()
    },
    setSelectedInfo() {
      const selectedInfo = {}
      for (const key in this.formType) { // 遍历formtype,格式化处理
        const item = this.formType[key]
        if (item.type === 'select') {
          selectedInfo[key] = false
        }
      }
      this.selectedInfo = selectedInfo
    },
    initEdit(row) {
      this.clear()
      if (this.itemInfo.id) { // 编辑,根据id获取列表
        for (const i in this.itemInfo) {
          if (this.copyRow[i]) {
            this.itemInfo[i] = this.copyRow[i]
          }
        }

        if (this.copyRow['id']) { this.itemInfo['id'] = this.copyRow['id'] }

        for (const key in this.formType) { // 遍历formtype,格式化处理
          const item = this.formType[key]
          if (item.type === 'deform') { // 将单选框值全部转换成文本格式
            if (this.itemInfo[key] || this.itemInfo[key] === 0) {
              this.itemInfo[key] = this.itemInfo[key].toString()
            }
          }
        }

        this.methodPath.getInfoFromId && this.$store.dispatch(this.methodPath.getInfoFromId, { id: this.itemInfo.id }).then(res => {
          this.checkIsUpdate(res.data)
        })
      }
    },
    checkIsUpdate(res) { // 修改过的就不更新了
      for (const i in res) {
        const item = this.itemInfo[i]
        const rowItem = this.copyRow[i]
        if (item === rowItem) {
          this.itemInfo[i] = res[i]
          if (i === 'isRecom') {
            console.log(this.itemInfo[i])
          }
        } else if (!item && item !== 0 && item !== false) {
          this.itemInfo[i] = res[i]
          if (i === 'isRecom') {
            console.log(this.itemInfo[i])
          }
        } else if (i === 'pbId') {
          this.itemInfo[i] = res[i]
        }
      }

      this.formatType(res)
    },
    initItemInfo() {
      const itemInfo = {}
      for (const i in this.formType) {
        if (this.formType[i].type === 'deform') { // 为单选框设置默认值
          itemInfo[i] = Object.keys(this.formType[i].data)[0]
        } else if (this.formType[i].default || this.formType[i].default === 0) { // 为全局设置默认值
          itemInfo[i] = this.formType[i].default
        } else {
          itemInfo[i] = ''
        }
      }
      this.itemInfo = itemInfo
    },
    initRules() {
      const rules = {}
      for (const i in this.formType) {
        const item = this.formType[i]
        if (item.message) {
          rules[i] = [
            { required: true, message: item.message, trigger: 'blur' }
          ]
        } else if (item.validator) {
          rules[i] = [
            { required: true, validator: item.validator }
          ]
        }
      }
      this.rules = rules
    },
    reFresh() {
      this.$forceUpdate()
    },
    isRich() { // 是否包含富文本
      const formType = this.formType
      for (const i in formType) {
        if (formType[i].type === 'rich') {
          this.itemInfo[i] = this.$refs[i][0].getContent()
        }
      }
    },
    setRichText(key) {
      const richText = this.$refs[key][0].getContent()
      if (richText) {
        this.itemInfo[key] = richText
      }
      const html = this.itemInfo[key]
      if (html) {
        this.$nextTick(() => {
          this.$refs[key][0].setContent(html)
        })
      }
      return ''
    },
    customChange(index) {
      this.formType[index].customChange.call(this, ...[...arguments].slice(1))
    },
    changeItemInfo(v) {
      this.itemInfo[v.key] = v.value
    },
    formatType(res) {
      for (const key in this.formType) {
        if (this.formType[key].type === 'deform') { // 处理单选
          this.itemInfo[key] = res[key].toString()
        }
      }
    },
    setForm(e, par, med) {
      // 重置表单
      this.formType = e
      // 表单赋值
      for (const key in par) {
        this.itemInfo[key] = par[key].toString()
      }
    },
    getName(index, valUseName) {
      if (!valUseName) {
        const result = this.formType[index].options.filter(item => item.id == this.itemInfo[index])
        if (result.length) {
          return result[0].name
        } else return false
      } else {
        return this.itemInfo[index]
      }
    },
    intInput(v, key) {
      if (v.length == 1 && v == 0) this.itemInfo[key] = ''
      else { this.itemInfo[key] = v.replace(/\D/g, '') }
    }

  }
}
</script>

<style lang='scss' scoped>
::v-deep{
  .el-upload{
    width: .73rem;
    height: .73rem;
    border-radius: 50%!important;
    overflow: hidden!important;
  }
}
.selected{
  height: .50rem;
  background: #F2F3F5;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: .16rem;
  padding-right: .20rem;
  .rightImg{
    width: .18rem;
    height: .18rem;
  }
}
.myComponent{
  width: 3.75rem;
  padding: .25rem .16rem;
    ::v-deep{
    .el-checkbox{
      margin-left: .3rem;
    }
    .el-form-item__label{
      font-size:.16rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      display: block;
      width: max-content!important;
    }
    .el-form-item__content{
      margin-left: unset!important;
    }
    .el-input{
       text-align: center;
      .el-input__inner{
        height: .5rem;

      }
    }
    .el-form-item{
      margin-bottom: .12rem;
      display: flex;
      flex-direction: column;
    }
    .el-form-item__error{
      width: 3.15rem;
    }

  }
}

.dialog-footer-button{
  width: 100%;
  height: .50rem;
}

.afterDiv{
  position: absolute;
  right: 0.15rem;
  bottom: .06rem;
  font-size: 0.16rem;
}

</style>
