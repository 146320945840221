<template>
  <el-form ref="ruleForm" class="register1" :rules="rules" label-position="right" :model="formLabelAlign">
    <el-form-item label="账号名" prop="account">
      <el-input v-model="formLabelAlign.account" placeholder="请输入" />
    </el-form-item>
    <el-form-item label="账号密码" prop="password">
      <div class="ddd">
        <el-input v-model="formLabelAlign.password" :type="passwordType1" placeholder="6位以上的密码" />
        <div class="account-img2">
          <img v-show="!passwordType1" :src="require(`@/assets/login/${$store.getters.moduleName}/5.png`)" @click="passwordType1='password'">
          <img v-show="!!passwordType1" :src="require(`@/assets/login/${$store.getters.moduleName}/7.png`)" @click="passwordType1=''">
        </div>
      </div>
    </el-form-item>
    <el-form-item label="确认密码" prop="agamePassword">
      <div class="ddd">
        <el-input v-model="formLabelAlign.agamePassword" :type="passwordType2" placeholder="重复输入密码" />
        <div class="account-img2">
          <img v-show="!passwordType2" :src="require(`@/assets/login/${$store.getters.moduleName}/5.png`)" @click="passwordType2='password'">
          <img v-show="!!passwordType2" :src="require(`@/assets/login/${$store.getters.moduleName}/7.png`)" @click="passwordType2=''">
        </div>
      </div>
    </el-form-item>
    <el-form-item label="邮箱地址" prop="email">
      <div class="asd">
        <div class="asdInside">
          <div class="input">
            <el-input v-model="formLabelAlign.email" placeholder="请输入邮箱" />
          </div>
          <div v-loading="loadingCode" type="primary" :disabled="code!=='获取验证码'" :class="{code2:code==='获取验证码'}" class="code btn center" @click="sendCode">{{ code }}</div>
        </div>
      </div>
    </el-form-item>
    <el-form-item label="验证码" prop="emailCode">
      <el-input v-model="formLabelAlign.emailCode" placeholder="请输入验证码" />
    </el-form-item>
    <el-form-item label="" prop="agree">
      <el-checkbox v-model="formLabelAlign.agree">同意<span @click.prevent="dialogVisible=true">《用户注册协议》</span></el-checkbox>
    </el-form-item>
    <el-form-item label="" style="margin-top:.3rem">
      <el-button :loading="loading" type="primary" class="mybutton" @click="goLogin">登录</el-button>
    </el-form-item>
    <el-dialog
      title="用户注册协议"
      :visible.sync="dialogVisible"
    >
      <xy />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-form>

</template>

<script>
import xy from '@/views/xy'
export default {
  components: { xy },
  data() {
    var checkAgree = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请同意注册协议'))
      } else {
        callback()
      }
    }
    var checkPasswordAgain = (rule, value, callback) => {
      if (!value || value.length < 6) {
        return callback(new Error('密码至少6位'))
      } else if (value !== this.formLabelAlign.password) {
        return callback(new Error('两次密码不一致'))
      } else {
        callback()
      }
    }
    return {
      formLabelAlign: {
        account: '',
        password: '',
        agamePassword: '',
        email: '',
        emailCode: '',
        agree: false
      },
      dialogVisible: false,
      loading: false,
      checked: false,
      code: '获取验证码',
      rules: {
        account: [
          { required: true, message: '请输入账号名,3位以上', trigger: 'blur' },
          { min: 3, message: '长度3位以上', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码,6位以上', trigger: 'blur' },
          { min: 6, message: '长度6位以上', trigger: 'blur' }
        ],
        agamePassword: [
          { required: true, trigger: 'blur', validator: checkPasswordAgain }
        ],
        agree: [
          { required: true, trigger: 'blur', validator: checkAgree }
        ]
      },
      loadingCode: false,
      passwordType1: 'password',
      passwordType2: 'password'
    }
  },

  mounted() {

  },

  methods: {
    sendCode() {
      const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (!this.formLabelAlign.email) {
        this.$message.error('请输入邮箱!')
        return
      } else if (!reg.test(this.formLabelAlign.email)) {
        this.$message.error('邮箱格式不正确')
        return
      }
      if (this.code === '获取验证码') {
        this.loadingCode = true
        this.$store.dispatch('login/SendEmail', { email: this.formLabelAlign.email, sendType: 1 }).then(res => {
          if (res.code === 0) {
            this.$message.success('验证码已发送')
            this.loadingCode = false
            this.code = 60
            this.sendCode()
          } else {
            this.loadingCode = false
          }
        })
      } else if (this.code > 0) {
        setTimeout(() => {
          this.code -= 1
          this.sendCode()
        }, 1000)
      } else {
        this.code = '获取验证码'
      }
    },
    goLogin() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('login/AccountAddAccount', this.formLabelAlign).then(res => {
            if (res.code === 0) {
              const { account, password } = this.formLabelAlign
              this.$store.dispatch('login/AccountLogin', { account, password }).then(res => {
                if (res.code === 0) {
                  this.$store.commit('app/LOGIN', res.data)
                  this.loading = false
                }
              })
            } else {
              this.loading = false
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.register1{
  padding: .1rem 0;
}
  ::v-deep{
    .el-checkbox{
      margin-left: .3rem;
    }
    .el-form-item__label{
      font-size:.16rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      display: block;
      width: max-content!important;
      margin-left: .3rem;
    }
    .el-form-item__content{
      margin-left: unset!important;
    }
    .el-input{
       text-align: center;
      .el-input__inner{
        width: 3.15rem;
        height: .5rem;

      }
    }
    .el-form-item{
      margin-bottom: .12rem;
    }
    .el-form-item__error{
      width: 3.15rem;
      margin-left: .3rem;
    }

  }
  .mybutton{
    height: .5rem;
    border-radius: 4px;
    width: 3.15rem;
    margin-left: .3rem;
  }
  .code2{
    background: #fff;
    color: var(--theme);
  }

  .asd{
    .asdInside{
      display: flex;
      width: 100%;
      ::v-deep{
       .el-input__inner{
          width: 1.8rem!important;
          margin-left: .3rem;
        }
      }

    }

    // ::v-deep{
    //   .el-input{
    //     width:2rem;
    //     display: block;
    //     .el-input__inner{
    //       width: unset!important;
    //       margin-left: .3rem;
    //     }
    //   }
    //   .el-form-item{
    //     width: 3.75rem;
    //   }

    // }
    .btn{
        position: absolute;
        height: .4rem;
        bottom: .05rem;
        left: 2.45rem;
        border-radius: 5px;
        overflow: hidden;
        font-size: .14rem;
        font-weight: 500;
        color: var(--theme);
        width: 1rem;
        &::after{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background:var(--theme);
          opacity: 0.1;
          content: "";
          display: block;
        }
      }
  }
.ddd{
  position: relative;
   .account-img2{
        width: .41rem;
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        right: .30rem;
        bottom: -.18rem;
        img{
          cursor: pointer;
        }
        & img:nth-child(1){
          width: .26rem;
        }
        & img:nth-child(2){
          width: .26rem;

        }
      }
}

</style>
