<template>
  <div class="xy" v-html="xy" />
</template>

<script>
export default {
  data() {
    return {
      xy: `<h2 style='text-align:center;margin-bottom:10px;'><strong>智慧党建平台</strong><strong>用户协议</strong></h2>
<p>智慧党建平台同意按照本协议的规定向用户提供服务，智慧党建平台使用人（以下称"用户"）需在认真阅读及独立思考的基础上认可、同意本协议的全部条款，并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击勾选"同意" 按钮（或实际使用智慧党建平台服务）即表示用户与智慧党建平台已达成协议，自愿接受本服务条款的所有内容。此后，用户不得以未阅读本服务条款内容作任何形式的抗辩。</p>
<p>制定本协议的目的在于维护智慧党建平台秩序，保障智慧党建平台用户的合法权益。制定本条例的依据是国家有关法律、法规，当本条例与国家法律、法规冲突时，以国家法律、法规为准。</p>
<p>&nbsp;</p>
<h4><strong>1．</strong><strong>智慧党建平台</strong><strong>帐号注册使用规则</strong></h4>
<p>智慧党建平台帐号注册资料包括但不限于用户的智慧党建平台帐号名称、头像、密码、注册或更新智慧党建平台帐号时输入的所有信息以及用户使用智慧党建平台各单项服务时输入的名称、头像等所有信息。</p>
<p>用户在注册智慧党建平台帐号时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在智慧党建平台帐号注册资料中出现违法和不良信息，且用户保证其在注册和使用帐号时，用户独立承担其发布内容的责任。用户对服务的使用必须遵守所有适用于服务的地方法律、国家法律和国际法律。用户承诺不得制作、复制、发布、传播以下信息：</p>
<p>（1）违反宪法或法律法规规定的；</p>
<p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
<p>（3）损害国家荣誉和利益的，损害公共利益的；</p>
<p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
<p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
<p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
<p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
<p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
<p>（9）以非法民间组织名义活动的；</p>
<p>（10）含有法律、行政法规禁止的其他内容的。</p>
<p>若用户提供给智慧党建平台的帐号注册资料不准确，不真实，含有违法或不良信息的，智慧党建平台有权不予注册，并保留终止用户使用智慧党建平台各项服务的权利。若用户以虚假信息骗取帐号注册或帐号头像、个人简介等注册资料存在违法和不良信息的，智慧党建平台有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用关联机构或社会名人注册帐号名称的，智慧党建平台有权注销该帐号，并向政府主管部门进行报告。</p>
<p>根据相关法律、法规规定以及考虑到智慧党建平台产品服务的重要性，用户同意：</p>
<p>（1）在注册智慧党建平台帐号时提交个人有效信息进行实名认证；</p>
<p>（2）提供及时、详尽及准确的账户注册资料；</p>
<p>（3）不断更新注册资料，符合及时、详尽准确的要求；</p>
<p>为使用户及时、全面了解智慧党建平台提供的各项服务，用户同意，智慧党建平台可以多次、长期向用户发送各类短信息而无需另行获得用户的同意。</p>
<p>用户同意与注册、使用智慧党建平台帐号相关的一切资料、数据和记录，包括但不限于智慧党建平台帐号、注册资料、所有登录、和相关的使用统计数字等归智慧党建平台所有。发生争议时，用户同意以智慧党建平台的系统数据为准，智慧党建平台保证该数据的真实性。</p>
<p>尽管有前述约定，对于用户使用智慧党建平台帐号享受智慧党建平台旗下单项服务时产生的一切数据，包括但不限于产品登录记录、及其他产品日志、产品客户服务记录、用户在产品中创造的社会网络内容等，归具体产品运营主体所有。发生争议时，用户同意以具体产品运营主体的系统数据为准。但是如果单项条款存在与前述不同的约定，则以单项条款约定为准。</p>
<p>&nbsp;</p>
<h4><strong>2．用户的权利与责任</strong></h4>
<p>（1）用户可享受智慧党建平台提供的各项服务。</p>
<p>（2）用户在智慧党建平台所进行的所有活动应遵守本协议及相关单项服务管理条例，遵守中华人民共和国法律、法规。</p>
<p>（3）用户要注意保管好用户名及密码，用户如发现帐号遭他人非法使用，应立即通知智慧党建平台管理者。因黑客行为或用户保管疏忽导致帐号、密码遭他人非法使用，智慧党建平台不承担任何法律责任。</p>
<p>（4）用户有权对智慧党建平台提出批评、意见、建议，有权就智慧党建平台管理相关工作向智慧党建平台提出咨询。</p>
<p>（5）用户需遵守网络道德，注意网络礼仪，做到文明上网。</p>
<p>（6）青少年用户特别提示：青少年用户必须遵守全国青少年网络文明公约，要善于网上学习，不浏览不良信息；要诚实友好交流，不侮辱欺诈他人；要增强自护意识，不随意约会网友；要维护网络安全，不破坏网络秩序；要有益身心健康，不沉溺虚拟时空。</p>
<p>（7）用户在智慧党建平台及其下属网页上发布信息或者利用智慧党建平台的服务时还必须符合其他有关国家和地区的法律规定以及国际法的有关规定。用户需遵守法律法规的规定使用智慧党建平台服务。</p>
<p>（8）用户不得利用智慧党建平台的服务从事以下活动：</p>
<p>(a) 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；</p>
<p>(b) 未经允许，对计算机信息网络功能进行删除、修改或者增加的；</p>
<p>(c) 未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；</p>
<p>(d) 故意制作、传播计算机病毒等破坏性程序的；</p>
<p>(e) 其他危害计算机信息网络安全的行为。</p>
<p>（9）用户不得以任何方式干扰智慧党建平台的服务。</p>
<p>（10）用户不得滥用智慧党建平台的服务，包括但不限于：利用智慧党建平台提供的账号服务发送虚假信息，利用智慧党建平台服务进行侵害他人知识产权或者合法利益的其他行为。</p>
<p>（11）用户应遵守智慧党建平台的所有其他规定和程序。</p>
<p>用户须对自己在使用智慧党建平台服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在智慧党建平台首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予智慧党建平台等额的赔偿。若用户违反以上规定，智慧党建平台有权作出独立判断立即暂停或终止对用户提供部分或全部服务，包括锁定、注销、删除用户智慧党建平台邮箱帐号等措施。用户理解，如果智慧党建平台发现其网站传输的信息明显属于上述所列内容之一，依据中国法律，智慧党建平台有义务立即停止传输，保存有关记录，向国家有关机关报告，并且删除含有该内容的地址、目录或关闭服务器。</p>
<p>&nbsp;</p>
<h4><strong>3．隐私权保护</strong></h4>
<p>（1）智慧党建平台保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在智慧党建平台的非公开内容，下列情况除外：</p>
<p>（a）用户本人（或监护人）授权社区透露这些信息；</p>
<p>（b）根据法律规定，对于违反有关法律法规、侵犯他人隐私和名誉等内容，智慧党建平台具有删除信息、保存有关记录并依法向有关部门提供的权利。</p>
<p>（2）在不透露用户隐私资料的前提下，智慧党建平台有权对整个用户数据库进行分析并对用户数据库进行适当的使用。（详见智慧党建平台个人信息保护政策）</p>
<p>&nbsp;</p>
<h4><strong>4. 服务变更、中断或终止</strong></h4>
<p>鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及其他无法控制的情形），用户同意智慧党建平台有权随时中断或终止部分或全部的服务，若发生该等中断或中止服务的情形，智慧党建平台将尽可能及时通过网页公告、系统通知、短信提醒或其他合理方式通知受到影响的用户。</p>
<p>用户理解，智慧党建平台需要定期或不定期地对相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，智慧党建平台无需为此承担任何责任，但智慧党建平台应尽可能事先进行通告。由于不可抗拒的原因（含系统维护和升级），导致用户数据丢失、服务暂停或停止，智慧党建平台不承担赔偿及其他连带的法律责任，但将尽力减少因此而给用户造成的损失和影响。</p>
<p>&nbsp;</p>`
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .xy{
    height:50vh;
    overflow-y: auto;
    margin: auto;
    padding: 30px 0;
  }
</style>
