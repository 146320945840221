<template>
  <div class="serverContent">
    <div v-for="(item,index) in dataList" :key="index" class="item" @click="$go(`/idea/ConsultingList/detail?id=${item.id}`)">
      <div class="left">
        <div class="title oneLine">{{ item.title }}</div>
        <div class="bottom">
          <div v-if="item.status==1" class="greenDiv">未回复</div>
          <div v-else class="redDiv">已回复</div>
          <div class="createTime">{{ item.createTime }}</div>
        </div>
      </div>
      <img class="right" :src="require('@/assets/right.png')">
    </div>

  </div>

</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Number,
      default: 0
    }

  },
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .serverContent{
    .item{
      padding: .2rem .15rem .15rem .15rem;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: .15rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left{
        flex: 1;
        .title{
          font-size: .16rem;
          font-weight: 500;
          color: #333333;
        }
        .bottom{
          margin-top: .11rem;
          display: flex;
          align-items: center;
          .createTime{
            font-size: .13rem;
            font-weight: 500;
            color: #999999;
            margin-left: .11rem;
          }
        }

      }
      .right{
        width: .18rem;
        height: .2rem;
      }
    }
  }
</style>
