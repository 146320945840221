<template>
  <div class="navTop center">
    <div v-if="backUrl" style="height:0.435rem" class="center back" @click="$goBack()">
      <img class="backImg" :src="require(`@/assets/${$route.meta.backImg?$route.meta.backImg : 'back-white'}.png`)">
    </div>
    {{ $store.state.app.routeName }}
    <div v-if="backUrl" style="height:0.435rem" class="center right" @click="$go('/')">
      <img class="rightImg" :src="require('@/assets/home2.png')">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    backUrl() {
      let path = this.$route.path
      path = path.replace(/^\//, '')
      if (path.split('/').length <= 1) return false
      else return '/' + path.split('/').slice(0, -1).join('/')
    }
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
  .navTop{
    height: 0.435rem;
    background: var(--theme);
    font-size: .18rem;
    font-weight: bold;
    color: #FFFFFF;
    position: relative;
    .back{
      position: absolute;
      left: .16rem;
      .backImg{
        width: .20rem;
      }
    }
    .right{
      position: absolute;
      right: .16rem;
      .rightImg{
        width: .24rem;
      }
    }

  }
</style>
