<template>
  <div class="server">
    <div class="notes">
      <div class="title">{{ this.$store.getters.info.fristName }}工作简介：</div>
      <div class="rich" v-html="$setContent($store.getters.info.fristNotes)" />

    </div>
    <div class="bigLine">
      <div class="button center" @click="$go('/home/Secretary/Detail')">查看详情</div>
    </div>

    <div class="line" />
    <div class="selectedOut">
      <div :class="{selected:selected==0}" @click="changeSelected(0)">工作动态</div>
      <div :class="{selected:selected==1}" @click="changeSelected(1)">工作纪实</div>
    </div>

    <div class="content">
      <searchContent ref="searchcontent" />
    </div>
  </div>
</template>

<script>
import searchContent from './components/searchContent.vue'
export default {
  components: { searchContent },
  data() {
    return {
      selected: 0
    }
  },

  mounted() {

  },

  methods: {
    changeSelected(select) {
      if (select === this.selected) return
      else {
        this.$refs.searchcontent.list = []
        this.$changeParam({ selected: select }, true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .server{
    display: flex;
    flex-direction: column;
    background-color: #f2f3f5;
    .content{
      flex: 1;
      background: #fff;
    }
    .selectedOut{
      padding: .155rem .15rem 0 .15rem;
      display: flex;
      background: #fff;
      div{
        font-size: .17rem;
        font-weight: 500;
        color: #999999;
        margin-right: .245rem;
        padding-bottom: .1rem;
      }
      .selected{
        color: var(--theme);
        border-bottom: 2px solid var(--theme);
      }
    }

    .notes{
      padding: .2rem .17rem .31rem .17rem;
      background: #fff;
      max-height: 30%;
      display: flex;
      flex-direction: column;
      .rich{
        flex:1;
        overflow-y: auto;
      }
      .title{
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
        margin-bottom: .20;
      }

    }

    .line{
      height: .1rem;
      background: #f2f3f5;
    }
  }

  .bigLine{
    background: #fff;
    padding: 0rem .16rem .31rem .16rem;
  }

  .button{
        height: .44rem;
        background: #FFFFFF;
        border: 1px solid var(--theme);
        border-radius: 2px;

        font-size: .16rem;
        font-weight: bold;
        color: var(--theme);
      }
</style>
