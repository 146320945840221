// 常量统一放这,防止遗忘位置。。。

export const throttleUrl = [ // 节流
  { // 登录接口
    id: '/Login/Login', // 节流的请求地址 在api文件夹里面定义的
    delay: 2000 // 延时多久,不填默认一秒
  },
  { // 登录接口
    id: '/PBSiteTemplate/UseTemp', // 节流的请求地址
    delay: 2000, // 延时多久,不填默认一秒
    msg: '操作太快,休息一下哦~' // 提示语,不填也行
  }
]
