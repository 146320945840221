/* xx*/
import axios from 'axios'
// var uploadpath = window.location.protocol +"//10000.165183.com/UserControls/upload_json.ashx?action=FileSave&dir={{dir}}&savedir={{dir}}/&fext={{fext}}"
var uploadpath =
  window.location.protocol +
  '//10000.165183.com/UserControls/upload_json.ashx?dir=default'

// that 按钮id
// files 文件信息
// dir 文件夹名
// successfunction 成功回调
// errfunction 失败回调
function uploadFiles(that, files, dir1, successfunction, errfunction) {
  const file = files[0]
  const param = new FormData() // 创建form对象
  if (dir1 == 'blob') {
    param.append('imgFile', file, '0.mp3')
  } else {
    param.append('imgFile', file)
  }

  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  } // 添加请求头
  axios
    .post(uploadpath, param, config)
    .then(response => {
      const data = response.data
      if (data.error == 0) {
        if (typeof successfunction == 'function') {
          successfunction(data)
          that.value = null
        } else {
          that.value = null
        }
      } else {
        if (typeof errfunction == 'function') {
          errfunction(data)
        }
      }
    })
    .catch(err => {
      if (typeof errfunction == 'function') {
        errfunction(err)
      }
    })
}

export default {
  uploadFiles
}
