<template>
  <div class="changeInfo">
    <div class="content">
      <component
        :is="path"
        ref="editOrCreate"
        style="background: #fff;"
        :init-query="initQuery"
        :dialog-title="dialogTitle"
        :form-type="formType"
        :method-path="methodPath"
        :row="dialogTitle === '编辑' ? selectRow : null"
        @reFresh="reFresh"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkTel = (rule, value, callback) => { // 编辑验证
      const re = /^1[3456789]{1}\d{9}$/
      if (!re.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      }
      callback()
    }
    return {
      initQuery: {},
      dialogTitle: '',
      methodPath: { // 接口必传
        create: 'login/AccountUpdateAccount', // 创建接口
        edit: 'login/AccountUpdateAccount', // 编辑接口
        getInfoFromId: 'login/AccountGetAccountInfo'
      },
      path: () => import('@/components/tableComponent/editOrCreate.vue'), // 编辑页面,如需要自定义可以引用自己的文件
      formType: { // 编辑按钮内容
        photo: {
          label: '头像',
          type: 'img',
          message: '请上传头像'
        },
        nickname: {
          label: '昵称',
          message: '请输入登录账号'
        },
        tel: {
          label: '手机',
          message: '请输入登录账号',
          validator: checkTel
        },
        phone: { label: '电话' },
        qq: { label: 'qq号' },
        email: { label: '邮箱' },
        postalCode: { label: '邮编' },
        id: { label: 'id', default: this.$store.getters.userInfo.id, type: 'hidden' },
        address: { label: '地址' }
      }
    }
  },
  methods: {
    reFresh() {
      this.$store.dispatch('login/AccountGetAccountInfo', { id: this.$store.getters.userInfo.id }).then(res => {
        if (res.code == 0) {
          this.$store.commit('app/CHANGE_USERINFO', res.data)
        }
      })
      this.$goBack(1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .changeInfo{
    .content{
      display: flex;
      flex-direction: column;
      background: #fff;
    }
  }
</style>
