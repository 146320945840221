<template>
  <contents :content-data="contentData" :method-path="methodPath" />
</template>

<script>
import contents from '@/components/contentComponent/content.vue'
import textClass from '../common.js'
export default {
  components: { contents },
  data() {
    return {
      contentData: [
        { id: 'name', label: '', style: { color: '#333333', fontSize: '18px' }, class: ['oneLine', 'titleClass'] },
        { id: 'idCardNo', label: '身份证号' },
        { id: 'lv', label: '困难程度' },
        { id: 'address', label: '家庭地址' },
        { id: 'strStatus', label: '申请状态', type: 'status', textClass: (val, routeName) => textClass(val, routeName) }, // routeName 路由最后面那一截名字 /xxx 里的xxx
        { id: 'createTime', label: '申请时间', style: { 'margin-top': '4px' }}
      ],
      methodPath: { // 接口必传
        search: 'personal/DifficultyPeopleGetDiffPersonList' // 查找接口

      }
      // routeName: this.$route.path.split('/').slice(-1)[0] // 默认取路由最后面那一截 /personal/Demand
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
