/** 重置message，防止重复点击重复弹出message弹框 */
import { Message } from 'element-ui'
let messageInstance = null
let time = null
const mainMessage = function DoneMessage(options) {
  // 如果弹窗已存在先关闭
  if (options.message === '亲,操作太快了~' && !time && messageInstance) {
    messageInstance.close()
    time = new Date()
  } else if (time && options.message === '亲,操作太快了~' && new Date() - time < 1000) {
    time = new Date()
    return
  } else if (messageInstance) {
    time = null
    messageInstance.close()
  }

  messageInstance = Message(options)
}
const arr = ['success', 'warning', 'info', 'error']
arr.forEach(function(type) {
  mainMessage[type] = function(options) {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return mainMessage(options)
  }
})
export const message = mainMessage
