<template>
  <div class="idea">
    <div class="itemOut">
      <div v-for="(item,index) in dataList" :key="index" class="item center" @click="$go(item.url)">
        <img class="icon" :src="item.img">
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [
        { name: '群众随手拍', img: require('@/assets/idea/1.png'), url: '/idea/Readily' },
        { name: '在线投诉', img: require('@/assets/idea/2.png'), url: '/idea/ConsultingList?type=在线投诉' },
        { name: '留言建议', img: require('@/assets/idea/3.png'), url: '/idea/AddProposal' },
        { name: '在线咨询', img: require('@/assets/idea/4.png'), url: '/idea/ConsultingList?type=在线咨询' },
        { name: '需求对接', img: require('@/assets/idea/5.png'), url: '/idea/DemandCreate' },
        { name: '对话书记', img: require('@/assets/idea/6.png'), url: '/idea/ConsultingList?type=对话书记' },
        { name: '困难帮扶', img: require('@/assets/idea/7.png'), url: '/idea/CreateDiff' },
        { name: '监督举报', img: require('@/assets/idea/8.png'), url: '/idea/ConsultingList?type=监督举报' },
        { name: '信息监管', img: require('@/assets/idea/9.png'), url: '/idea/ConsultingList?type=信息监管' }
      ]
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .itemOut{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .2rem .16rem;
    .item{
      margin-bottom: .15rem;
      width: 1.65rem;
      height: .55rem;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
      .icon{
        width: .26rem;
        margin-right: .1rem;
      }
      .name{
        font-size: .15rem;
        font-weight: bold;
        color: #333333;
        width: .78rem;
      }
    }

  }
</style>
