
const routes = [
  {
    path: '/home/login',
    component: () => import('@/views/login'),
    meta: { name: '登录' }
  },
  {
    path: '/home/login/register',
    component: () => import('@/views/login/sonPage/register'),
    meta: { name: '注册' }
  },
  {
    path: '/home/login/forget',
    component: () => import('@/views/login/sonPage/forget'),
    meta: { name: '找回密码' }
  },
  {
    path: '/home',
    component: () => import('@/views/home/index.vue'),
    meta: { name: '智慧党建' }
  },
  {
    path: '/home/search',
    component: () => import('@/views/home/sonPage/search'),
    meta: { name: '搜索' }
  },
  {
    path: '/home/Secretary',
    component: () => import('@/views/home/sonPage/Secretary'),
    meta: { name: '' }
  },
  {
    path: '/home/Secretary/Detail',
    component: () => import('@/views/home/sonPage/SecretaryDetail'),
    meta: { name: '详情' }
  },
  {
    path: '/home/Secretary/Detail',
    component: () => import('@/views/home/sonPage/SecretaryDetail'),
    meta: { name: '详情' }
  },
  {
    path: '/home/Secretary/ArticleDetail',
    component: () => import('@/views/home/sonPage/SecretaryArticleDetail'),
    meta: { name: '工作动态详情' }
  },
  {
    path: '/home/GetPbDem',
    component: () => import('@/views/home/sonPage/GetPbDem'),
    meta: { name: '支部风采' }
  },
  {
    path: '/home/GetPbDem/Detail',
    component: () => import('@/views/home/sonPage/GetPbDemDetail'),
    meta: { name: '支部风采' }
  },
  {
    path: '/home/notice',
    component: () => import('@/views/home/sonPage/notice'),
    meta: { name: '公告' }
  }
]

export default routes
