<template>
  <div class="titleStyle">
    <div class="first aliCenter">
      <div class="img" />
      <div class="text">{{ text }}</div>
    </div>
    <div class="right" @click="go">
      {{ rightText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: '查看更多 >'
    }
  },
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {
    go() {
      this.$go(this.moreUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
  .titleStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .235rem;
    .first{
      .img{
        width: .06rem;
        height: .20rem;
        background: var(--theme);
        border-radius: 0px .03rem .03rem 0px;
      }
      .text{
        font-size: .20rem;
        font-weight: bold;
        color: #333333;
        margin-left: .08rem;
      }
    }
    .right{
      font-size: .14rem;
      font-weight: 500;
      color: #999999;
    }
  }
</style>
