<template>
  <div class="mine">
    <img class="bg" :src="require('@/assets/template/first/home/bg.png')">
    <div class="a">
      <img class="setting" :src="require('@/assets/mine/setting.png')" @click="$go('/mine/setting')">
      <div class="topContent">
        <div class="top">
          <div class="left">
            <img v-if="!userInfo.photo" class="photo" :src="require('@/assets/mine/default.png')">
            <img v-else class="photo" :src="$beforeUrl(userInfo.photo)">
            <div class="userInfo">
              <div class="name">{{ userInfo.nickname }}</div>
              <!-- <div class="name">{{userInfo.nickname}}</div> -->
            </div>
          </div>
          <div class="edit center" @click="$go('/mine/UpdateAccount')">编辑资料</div>
        </div>
        <div class="bottom">
          <div v-for="(item,index) in topInfo" :key="index" class="item" @click="$go(item.path)">
            <img class="itemImg" :src="item.img">
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>

      <div class="bottomContent">
        <div v-for="(item,index) in bottomInfo" :key="index" class="item" @click="$go(item.path)">
          <div class="left">
            <img class="itemImg" :src="item.img">
            <div class="text">{{ item.name }}</div>
          </div>
          <img class="go" :src="require('@/assets/right.png')">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topInfo: [
        { name: '群众随手拍', img: require('@/assets/mine/1.png'), path: '/mine/Readily' },
        { name: '留言建议', img: require('@/assets/mine/2.png'), path: '/mine/Proposal' },
        { name: '需求对接', img: require('@/assets/mine/3.png'), path: '/mine/Demand' },
        { name: '困难帮扶', img: require('@/assets/mine/4.png'), path: '/mine/DifficultyPeople' }
      ],
      bottomInfo: [
        { name: '在线咨询', img: require('@/assets/mine/5.png'), path: '/mine/Online' },
        { name: '对话书记', img: require('@/assets/mine/6.png'), path: '/mine/Dialogue' },
        { name: '监督举报', img: require('@/assets/mine/7.png'), path: '/mine/Supervise' },
        { name: '信息监管', img: require('@/assets/mine/8.png'), path: '/mine/Information' },
        { name: '在线投诉', img: require('@/assets/mine/9.png'), path: '/mine/Complain' }
      ]
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .mine{
    .a{
      position: relative;
      z-index: 1;
    }
    padding: .15rem 0;
    background: #f2f3f5;
    .bg{
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 3.75rem;
    }
    .setting{
      margin-right: .15rem;
      margin-left: auto;
      width: .17rem;
      height: .17rem;
      display: block;
    }
    .topContent{
      width: 3.43rem;
      margin: .18rem auto .15rem auto;
      background: linear-gradient(180deg, #FFEFD7, #FFFFFF);
      border-radius: 2px;
      padding: .13rem .13rem .22rem .13rem;
      .top{
        display: flex;
        justify-content: space-between;
        .left{
          display: flex;
          align-items: center;
          .photo{
            width: .73rem;
            height: .73rem;
            border-radius: 50%;
            margin-right: .16rem;

          }
          .userInfo{
              .name{
                font-size: .18rem;
                font-weight: bold;
                color: var(--theme);
              }
          }
        }
        .edit{
          margin-right: -.13rem;
          width: .75rem;
          height: .24rem;
          background: rgba(255, 162, 0,.2);
          border-radius: .12rem 0px 0px .12rem;
          font-size: .13rem;
          font-weight: 500;
          color: var(--theme);
        }
      }
      .bottom{
        margin-top: .17rem;
        display: flex;
        justify-content: space-between;
        .item{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .itemImg{
            width: .3rem;
            height: .3rem;
          }
          .text{
            font-size: .14rem;
            font-weight: 500;
            color: #333333;
            margin-top: .07rem;
          }
        }
      }
    }
    .bottomContent{
      background: #ffffff;
      padding: 0 .19rem;
      .item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .19rem 0;
        position: relative;
        .left{
          display: flex;
          align-items: center;
          width: 1.03rem;
          justify-content: space-between;
          .itemImg{
            width: .23rem;
          }
          .text{
            font-size: .16rem;
            font-weight: 500;
            color: #333333;
          }
        }
        .go{
          width: .16rem;
          height: .18rem;
        }
        &::after{
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          content: "";
          display: block;
          height: 1px;
          background: #EEEEEE;
        }
        &:last-child::after{
          height: 0!important;
        }
      }
    }
  }
</style>
