<template>
  <div class="second">
    <div class="top">
      <div class="ooo">
        <img class="left" :src="require('@/assets/template/second/home/left.png')" @click="$go('/home/Secretary')">
        <div class="text">{{ this.$store.getters.info.fristName }}</div>
      </div>
      <logo />
      <div class="ooo">
        <img class="right" :src="require('@/assets/template/second/home/right.png')" @click="$go('/home/GetPbDem')">
        <div class="text">支部风采</div>
      </div>
    </div>

    <!-- 轮播图 -->
    <lunbo />

    <div class="searchOut">
      <div class="search" @click="$go('/home/search')">
        <img class="searchIcon" :src="require('@/assets/template/second/home/search.png')">
        <div class="text">请输入搜索关键词…</div>
      </div>
    </div>

    <!-- 中间八个图标 -->
    <middleEightIcon />

    <!-- 公告 -->
    <notice />

    <!-- 灰色的线 -->
    <div class="line" />

    <!-- 视频 -->
    <videoComponent />

    <div class="line" />

    <TextAndImg />

  </div>
</template>

<script>
import lunbo from './components/lunbo.vue'
import middleEightIcon from './components/middleEightIcon.vue'
import notice from './components/notice.vue'
import TextAndImg from './components/main/index.vue'
import videoComponent from './components/video.vue'
export default {
  components: { lunbo, middleEightIcon, notice, TextAndImg, videoComponent },
  data() {
    return {
    }
  },

  mounted() {

  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.ooo{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  .text{
    font-size: .08rem;
    margin-top: .03rem;
  }
}
.second{
  .line{
    height: .13rem;
    background: #f2f3f5;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .07rem .16rem .12rem .16rem;
    .left{
      width: .21rem;
    }
    .right{
      width: .21rem;
    }
  }

  .searchOut{
    margin-top: .15rem;
    position: relative;
    .search{
      width: 3.43rem;
      height: .36rem;
      background:  #F5F5F5;
      border-radius: .18rem;
      display: flex;
      align-items: center;
      margin: auto;
      .searchIcon{
        width: .15rem;
        height: .155rem;
        margin-left: .15rem;
        margin-right: .095rem;
      }
      .text{
        font-size: .14rem;
        font-weight: 500;
        color: #AAAAAA;
        opacity: 0.5;
      }
    }
    .zb{
      width: .40rem;
      height: .40rem;
      position: absolute;
      right: .16rem;
      top: 0;
    }
  }
}
</style>
