<template>
  <div class="server">
    <div class="content">
      <searchContent ref="searchcontent" />
    </div>
  </div>
</template>

<script>
import searchContent from './components/searchContent.vue'
export default {
  components: { searchContent },
  data() {
    return {
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .server{
    display: flex;
    flex-direction: column;
    background-color: #f2f3f5;
    .content{
      flex: 1;

    }
  }
</style>
