import Vue from 'vue'
import VueRouter from 'vue-router'
import home from './home'
import menu from './menu'
import server from './server'
import idea from './idea'
import mine from './mine'

Vue.use(VueRouter)

const routes = [
  ...home,
  ...menu,
  ...server,
  ...idea,
  ...mine,
  {
    path: '/',
    redirect: '/home'
  },
  { path: '*', redirect: '/404', hidden: true },
  {
    path: '/error',
    redirect: 'noRedirect',
    name: 'ErrorPages',
    meta: {
      title: 'Error Pages',
      icon: '404'
    },
    children: [
      {
        path: '401',
        component: () => import('@/views/error-page/401'),
        name: 'Page401',
        meta: { title: '401', noCache: true }
      },
      {
        path: '404',
        component: () => import('@/views/error-page/404'),
        name: 'Page404',
        meta: { title: '404', noCache: true, name: '404' }
      }
    ]
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true,
    meta: { name: '404' }
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
    meta: { name: '401' }
  },
  {
    path: '/redirect',
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
