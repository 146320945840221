<template>
  <div class="main">
    <van-tabs v-model="active" sticky color="var(--theme)" title-active-color="var(--theme)" title-inactive-color="#999" @change="change">
      <van-tab v-for="(item,index) in menuList" :key="index" :title="item.name" title-style="font-size: .15rem;font-weight: 500;" />
      <contentComponent ref="sonPage" class="content" />
    </van-tabs>
  </div>
</template>

<script>
import { Tab, Tabs } from 'vant'
import contentComponent from './components/content.vue'
export default {
  components: { 'van-tab': Tab, 'van-tabs': Tabs, contentComponent },
  data() {
    return {
      active: 0,
      menuList: [
        // { name: '时政要闻' },
        // { name: '党建动态' },
        // { name: '专题报道' },
        // { name: '政策法规' },
        // { name: '志愿活动' }
      ],
      param: { type: 1 }
    }
  },
  watch: {
  },

  mounted() {

  },
  created() {
    this.getNavList() // 获取头部导航
  },

  methods: {
    getNavList() {
      this.menuList.push(
        this.$store.state.app.colList[0],
        this.$store.state.app.colList[3],
        this.$store.state.app.colList[1],
        this.$store.state.app.colList[2],
        this.$store.state.app.colList[13]
      )

      // return new Promise((resolve, reject) => {
      //   this.$store.dispatch('main/NewsCategoryGetCategoryList', { type: 1 }).then(res => {
      //     if (res.code === 0) {
      //       console.log(res.data)
      //       this.menuList.map(item => {
      //         for (const item2 of res.data) {
      //           if (item.name == item2.name || (item2.name == '志愿者活动' && item.name == '志愿活动')) {
      //             item.id = item2.id
      //           }
      //         }
      //       })
      //       this.isReady = true
      //       resolve()
      //     } else {
      //       reject()
      //     }
      //   })
      // })
    },
    getActiveForId(id = (this.param.id)) {
      if (!id) {
        const name = this.param.name
        for (const index in this.menuList) {
          const item = this.menuList[index]
          if (item.name === name) {
            this.active = index * 1
            break
          }
        }
      } else {
        for (const index in this.menuList) {
          const item = this.menuList[index]
          if (item.id === id) {
            this.active = index * 1
            break
          }
        }
      }
    },
    change(v) {
      this.$refs.sonPage.onRefresh()
      this.$refs.sonPage.onLoad()
    }
  }
}
</script>

<style lang="scss" scoped>
  .main{

    display: flex;
    flex-direction: column;
    .content{
      height: 100vh;
      overflow-y: auto;
    }
  }
  ::v-deep{
    .van-tab__text--ellipsis{
      width: max-content;
    }
  }

</style>
