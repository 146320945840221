<template>
  <div class="login">
    <div class="navTop center">
      <div style="height:0.5rem" class="center back" @click="$goBack()">
        <img class="backImg" :src="require(`@/assets/back-black.png`)">
      </div>
      {{ $store.state.app.routeName }}
      <div style="height:0.5rem" class="center home" @click="$go('/home')">
        <img class="backImg" :src="require(`@/assets/home.png`)">
      </div>
    </div>

    <!-- 正常登录 -->
    <login v-show="type===0" class="content" />

  </div>
</template>

<script>
import login from './components/login.vue'
export default {
  components: { login },
  data() {
    return {
      type: 0, // 0普通登录 1微信扫码登录 2注册 3忘记密码
      erWeiMa: '',
      loading: true,
      show: false,
      urlData: '',
      param: {}
    }
  },
  watch: {
  },
  destroyed() {
  },
  created() {
    this.init()
  },
  methods: {
    checkLogin() {
      this.$store.dispatch('login/AccountGetH5Accoun', { openId: this.param.openid, nickName: this.param.NickName }).then(res => {
        if (res.code == 0) {
          this.$store.commit('app/LOGIN', res.data)
          res.data.isWx = true
          window.location.href = window.location.origin + '/home'
          this.$message.success('登录成功')
        } else {
          this.getUrl()
        }
      })
    },
    getUrl() {
      this.$store.dispatch('login/AccountGetH5AuthUrl', { callbackUrl: window.location.href }).then(res => {
        this.urlData = { path: res.data }
      })
    },
    init() {
      this.param = this.$getParams()
      if (this.param.openid) {
        this.checkLogin()
      } else {
        this.getUrl()
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.login{
  display: flex;
  flex-direction: column;
}
  .navTop{
    height: 0.5rem;
    font-size: .24rem;
    font-weight: bold;
    color: #333333;
    position: relative;
    border-bottom: 1px solid #eee;
    .back{
      position: absolute;
      left: .16rem;
      .backImg{
        width: .20rem;
      }
    }
    .home{
      position: absolute;
      right: .16rem;
      .backImg{
        width: .20rem;
      }
    }

  }
  .content{
    flex: 1;
    overflow-y:auto;
  }

  .erWeiMa{
  width: 2.50rem;
  height: 2.50rem;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  img{
    width: 2.10rem;
  }
}
</style>
