<template>
  <div
    class="SecretaryDetail"
  >

    <div class="rich2">
      <div class="title">
        <div class="left" />
        <div class="text">{{ this.$store.getters.info.fristName }}工作简介</div>
      </div>
      <div class="content rich" v-html="$setContent(info.rich)" />
    </div>

    <div class="line2" />

    <div class="rich2">
      <div class="title">
        <div class="left" />
        <div class="text">{{ this.$store.getters.info.fristName }}</div>
      </div>
      <div class="content2">
        <div
          v-for="(item, index) in info.list"
          :key="index"
          class="contentItem"
        >
          <img class="img" :src="$beforeUrl(item.avatar)">
          <div class="name">{{ item.name }}</div>
          <div :class="{ hide: !item.honor }" class="youXiu center">
            {{ item.honor }}
            <div class="youXiuBg" />
          </div>
        </div>
      </div>
    </div>

    <div class="line3" />

  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        rich: this.$store.getters.info.fristNotes,
        list: []
      }
    }
  },

  mounted() {
    this.$parent.loading = false
  },
  created() {
    this.getData()
  },

  methods: {
    goBack() {
      this.$go('/Secretary')
    },
    getData() {
      this.$store.dispatch('main/SecretaryGetFirst').then((res) => {
        if (res.code === 0) {
          this.info.list = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.SecretaryDetail {
  padding: .25rem .16rem;
  .line2 {
    height: 1px;
    background: #eeeeee;
    margin-top: .2rem;
    margin-bottom: .2rem;
  }
  .rich2 {
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: .20rem;
      .left {
        width: .04rem;
        height: .18rem;
        background: var(--theme);
        margin-right: .07rem;
      }
      .text {
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
        line-height: .32rem;
      }
    }
  }
  .content2 {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .contentItem:nth-child(3n){
      margin-right: unset!important;
    }
    .contentItem {
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .20rem;
      margin-right: .14rem;
      .img {
        width: 1.05rem;
        height: 1.47rem;
      }
      .name {
        font-size: .16rem;
        font-weight: 400;
        color: #333333;
        margin-top: .1rem;
        margin-bottom: .1rem;
      }
      .youXiu {
        height: .20rem;
        width: max-content;
        border: 1px solid var(--theme);
        border-radius: 4px;
        font-size: .12rem;
        font-weight: 400;
        color: var(--theme);
        position: relative;
        overflow: hidden;
        padding: 0 .1rem;
        .youXiuBg {
          background: var(--theme);
          opacity: 0.1;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: -1;
        }
      }
    }
  }
}
.hide {
  visibility: hidden;
}

</style>
