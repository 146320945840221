<template>
  <div class="eightIcon">
    <div class="itemOut">
      <div v-for="(item,index) in dataList.slice(0,4)" :key="index" class="item colCenter" @click="$go(item.url)">
        <img :src="item.img" class="img">
        <div class="text">{{ item.name }}</div>
      </div>
    </div>
    <div class="itemOut">
      <div v-for="(item,index) in dataList.slice(4,8)" :key="index" class="item colCenter" @click="$go(item.url)">
        <img :src="item.img" class="img">
        <div class="text">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      dataList: [
        { name: '时政要闻', url: '/menu/main?name=时政要闻&type=1', img: require('@/assets/template/first/home/1.png') },
        { name: '专题报道', url: '/menu/main?name=专题报道&type=1', img: require('@/assets/template/first/home/2.png') },
        { name: '会议纪要', url: '/menu/main?name=会议纪要&type=1', img: require('@/assets/template/first/home/3.png') },
        { name: '先锋模范', url: '/menu/main?name=先锋模范&type=1', img: require('@/assets/template/first/home/4.png') },
        { name: '三务公开', url: '/menu/main?name=三务公开&type=4', img: require('@/assets/template/first/home/5.png') },
        { name: '党建视频', url: '/menu/main?name=党建视频&type=2', img: require('@/assets/template/first/home/6.png') },
        { name: '困难帮扶', url: '/menu/main?id=1&type=5', img: require('@/assets/template/first/home/7.png') },
        { name: '政策法规', url: '/menu/main?name=政策法规&type=1', img: require('@/assets/template/first/home/8.png') }
      ]
    }
  },

  mounted() {

  },
  created() {
    this.$store.state.app.colList.forEach((item, index) => {
      const items = this.dataList[index]
      items.name = item.name
      items.url = `/menu/main?name=${item.name}&type=1`
    })
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .eightIcon{
    padding:.0rem .16rem .145rem .16rem;
    .itemOut{
      display: flex;
      justify-content: space-between;
      margin-top: .17rem;
    }
    .item{
      .img{
        width: .45rem;
        height: .45rem;
      }
      .text{
        font-size: .13rem;
        font-weight: bold;
        color: #333333;
        margin-top: .075rem;
      }
    }
  }
</style>
