import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/vuePrototype'

import './permission'

import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'

Vue.prototype.$tinymce = tinymce
Vue.use(VueTinymce)
import logo from '@/components/logo'
Vue.component('logo', logo)
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Element, {
  size: 'medium' // set element-ui default size
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
