<template>
  <itemContent class="notice" :data-list="dataList" />
</template>

<script>
import itemContent from '@/views/home/components/first/components/content/TextAndImg.vue'
export default {
  components: { itemContent },
  data() {
    return {
      dataList: []
    }
  },

  mounted() {

  },
  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      this.$store.dispatch('home/HomeGetNotice', { top: 999 }).then((res) => {
        if (res.code === 0) {
          this.dataList = res.data
        } else {
          this.$message.error(res.msg || '请求失败,请刷新重试')
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .notice{
    padding: .2rem .16rem;
  }
</style>
