<template>
  <div class="Readily">
    <div class="content">
      <component
        :is="path"
        ref="editOrCreate"
        style="background: #fff;"
        :form-type="formType"
        :method-path="methodPath"
        @reFresh="reFresh"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkTel = (rule, value, callback) => { // 编辑验证
      const re = /^1[3456789]{1}\d{9}$/
      if (!re.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      }
      callback()
    }
    var options = [{ name: '选项一', id: '1' }, { name: '选项二' }, { name: '选项三' }]
    return {
      methodPath: { // 接口必传
        create: 'personal/ProposalAddProposal' // 创建接口
      },
      path: () => import('@/components/tableComponent/editOrCreate.vue'), // 编辑页面,如需要自定义可以引用自己的文件
      formType: {
        subject: {
          label: '留言主题',
          message: '请输入留言主题'
        },
        contacts: { label: '联系人', message: '请输入联系人' },
        tel: { label: '联系电话', validator: checkTel },
        pbId: { label: '党支部', type: 'select', options, message: '请选择党支部' }, // 下拉选择
        context: { // 富文本
          label: '详细内容',
          type: 'rich',
          message: '请输入详细内容'
        }
      }
    }
  },

  mounted() {

  },
  created() {
    this.getTypeList() // 获取下拉分类
  },

  methods: {
    getTypeList() {
      this.$store.dispatch('home/PbDropDownPb').then(res => {
        if (res.code === 0) {
          this.formType.pbId.options = res.data
        }
      })
    },
    reFresh() {
      this.$go('/mine/Proposal')
    }
  }
}
</script>

<style lang="scss" scoped>
    .Readily{
    background: #f5f9fa;
    flex: 1;
    .content{
      display: flex;
      flex-direction: column;
      background: #fff;
    }
  }
</style>
