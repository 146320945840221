<template>
  <div class="serverContent">
    <div v-for="(item,index) in dataList" :key="index" class="item">
      <div class="title onLine">{{ item.name }}</div>
      <div class="tel onLine">电话：{{ item.tel }}</div>
      <div class="address twoLine">地址：{{ item.address }}</div>
      <div class="myLine" />
      <div class="bottomButton">
        <div v-if="selected==1" class="center" @click="$go(item.url)">查看官网</div>
        <div class="center" @click="goMap(item)">地图导航</div>
        <div class="center" @click="call(item.tel)">拨打电话</div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Number,
      default: 0
    }

  },
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {
    goMap(item) {
      this.$showNotice(() => {
        const { latitude, longitude, address } = item
        const url = 'https://uri.amap.com/marker?position=' + longitude + ',' + latitude + '&name=' + address + '&callnative=1'
        window.location.href = url
      }, '即将跳转至高德地图,是否继续?')
      // const { latitude, longitude, name, tel, address } = item
      // this.$router.push({ path: '/server/map', query: { latitude, longitude, name, tel, address }})
    },
    call(tel) {
      window.location.href = `tel:${tel}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .serverContent{
    .item{
      padding: .2rem .15rem .15rem .15rem;
      background-color: #fff;
      border-radius: 4px;
      margin-bottom: .15rem;
      .title{
        font-size: .17rem;
        font-weight: bold;
        color: #333333;
      }
      .tel{
        font-size: .15rem;
        font-weight: 500;
        color: #999999;
        margin-top: .15rem;
      }
      .address{
        font-size: .15rem;
        font-weight: 500;
        color: #999999;
        line-height: .21rem;
        margin-top: .13rem;
      }
      .myLine{
        height: 1px;
        background: #EEEEEE;
        margin: .15rem 0;
      }
      .bottomButton{
        display: flex;
        justify-content: space-between;
        font-size: .14rem;
        font-weight: 500;
        color: #C21616;
        div{
          height: .36rem;
          border-radius: 2px;
          position: relative;
          margin-right: .15rem;
          flex: 1;
          &::after{
            display: block;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--theme);
            opacity: 0.1;
          }
        }
        &>div:last-child{
          margin-right: unset!important;
        }

      }
    }
  }
</style>
