<template>
  <div class="notice">
    <img class="img" :src="require('@/assets/template/second/home/notice.png')">
    <div class="right">
      <div class="text newOneline">
        <span v-for="(item,index) in textList" :key="index" class="item" @click="$go(`/menu/main/detail?id=${item.id}`)">{{ item.title }}</span>
      </div>
      <div class="more" @click="$go('/home/notice')">
        <img class="rightIcon" :src="require('@/assets/right.png')">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textList: []
    }
  },

  mounted() {

  },
  created() {
    this.getTextList()
  },

  methods: {
    getTextList() {
      this.$store.dispatch('home/HomeGetNotice', { top: 999 }).then(res => {
        if (res.code === 0) {
          this.textList = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .notice{
    padding-left: .21rem;
    padding-right: .16rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .165rem;
    .rightIcon{
      width: .16rem;
      height: .14rem;
    }
    .img{
      width: .31rem;
      height: .31rem;
    }
    .right{
      width: 2.97rem;
      height: .34rem;
      background: #F2F3F5;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: .10rem;
      padding-right: .125rem;
      .text{
        font-size: .14rem;
        font-weight: 500;
        color: #333333;
        width: 2.215rem;
        .item{
            margin-right: .1rem;
            width: max-content;
          }
      }
      .line{
        width: .01rem;
        height: .18rem;
        background: #DDDDDD;
      }
      .more{
        font-weight: 500;
        color: #999999;
        display: flex;
        align-items: center;
      }
    }
  }

</style>
