<template>
  <myImg
    class="ad"
    :src="$beforeUrl(ad.img)"
    @click.native="$go(ad.linkUrl)"
  />
</template>

<script>
import myImg from '@/components/myImg/index.vue'
export default {
  components: { myImg },
  props: {
    position: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ad: {}
    }
  },

  mounted() {

  },
  created() {
    this.getAd()
  },

  methods: {
    getAd() {
      this.$store
        .dispatch('home/HomeGetSiteAd', {
          top: 1,
          position: this.position,
          tempId: this.$store.getters.module
        })
        .then((res) => {
          if (res.code === 0 && res.data[0] && res.data[0].jsonText) {
            this.ad = JSON.parse(res.data[0].jsonText)[0]
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.ad {
  width: 3.75rem;
  height: 1.2rem;
}
</style>
