
// Demand 就是this.$route.path.split('/').slice(-1)[0]的值 ,默认取路由最后面那一截 /personal/Demand
export const statusData = {
  Demand: { // 需求对接页
    '待审核': { class: ['waitDiv'], reset: 'none' },
    '通过': { class: ['greenDiv'], del: 'none', cancel: 'none', reset: 'none' },
    '不通过': { class: ['redDiv'], cancel: 'none' },
    '已取消': { class: ['cancelDiv'], cancel: 'none', reset: 'none' }
  },
  Proposal: {
    '已回复': { class: ['greenDiv'] },
    '未回复': { class: ['redDiv'] }
  },
  Readily: {
    '已处理': { class: ['greenDiv'] },
    '待处理': { class: ['redDiv'] }
  },
  DifficultyPeople: { // 需求对接页
    '待审核': { class: ['waitDiv'], reset: 'none' },
    '通过': { class: ['greenDiv'], del: 'none', cancel: 'none', reset: 'none' },
    '不通过': { class: ['redDiv'], cancel: 'none', reset: 'none' },
    '已取消': { class: ['cancelDiv'], cancel: 'none', reset: 'none' }
  },
  Online: {
    '已回复': { class: ['greenDiv'] },
    '未回复': { class: ['redDiv'] }
  },
  Dialogue: {
    '已回复': { class: ['greenDiv'] },
    '未回复': { class: ['redDiv'] }
  },
  Supervise: {
    '已回复': { class: ['greenDiv'] },
    '未回复': { class: ['redDiv'] }
  },
  Information: {
    '已回复': { class: ['greenDiv'] },
    '未回复': { class: ['redDiv'] }
  },
  Complain: {
    '已回复': { class: ['greenDiv'] },
    '未回复': { class: ['redDiv'] }
  }
}

export default function(val, name) { // 这个是对应的状态对应对应的类
  return statusData[name][val] ? statusData[name][val].class : []
}
