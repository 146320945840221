<template>
  <div class="server">
    <div class="selectedOut">
      <div :class="{selected:selected==0}" @click="changeSelected(0)">办事指南</div>
      <div :class="{selected:selected==1}" @click="changeSelected(1)">公共服务</div>
    </div>

    <div class="content">
      <searchContent ref="searchcontent" />
    </div>
  </div>
</template>

<script>
import searchContent from './components/searchContent.vue'
export default {
  components: { searchContent },
  data() {
    return {
      selected: 0,
      text: '',
      total: 0
    }
  },

  mounted() {

  },

  methods: {
    changeSelected(select) {
      if (select === this.selected) return
      else {
        this.$refs.searchcontent.list = []
        this.$changeParam({ selected: select }, true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .server{
    display: flex;
    flex-direction: column;
    background-color: #f2f3f5;
    .content{
      flex: 1;

    }
    .selectedOut{
      padding: .155rem .15rem 0 .15rem;
      display: flex;
      div{
        font-size: .17rem;
        font-weight: 500;
        color: #999999;
        margin-right: .245rem;
        padding-bottom: .1rem;
      }
      .selected{
        color: var(--theme);
        border-bottom: 2px solid var(--theme);
      }
    }
  }
</style>
