export function getUrlParams(href) {
  var urlObj = {}
  const search = href ? (href.split('?').length > 1 ? '?' + href.split('?')[1] : '') : window.location.search
  if (!search) { return {} }
  var urlParams = decodeURI(search).substring(1)
  var urlArr = urlParams.split('&')
  for (var i = 0; i < urlArr.length; i++) {
    var urlArrItem = urlArr[i].split('=')
    urlObj[urlArrItem[0]] = urlArrItem[1]
  }

  return urlObj || {}
}
