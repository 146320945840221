<template>

  <div class="ListOut">

    <div v-if="!list.length && finished && !loading" class="empty">
      <img :src="require('@/assets/searchEmpty.png')">
      <div class="text">暂无搜索结果</div>
    </div>

    <van-pull-refresh v-show="!(list.length == 0 && finished)" v-model="refreshing" class="List" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        error-text="请求失败，点击重新加载"
        :error.sync="error"
        class="vanList"
        @load="onLoad"
      >
        <itemContent v-if="$parent.selected==0" style="padding:.245rem .16rem" :no-show-empty="true" :data-list="list" />
        <videoContent v-else :video-list="list" />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { List, PullRefresh } from 'vant'
import itemContent from '@/views/home/components/first/components/content/TextAndImg.vue'
import videoContent from './videoContent.vue'
export default {
  components: { 'van-pull-refresh': PullRefresh, 'van-list': List, itemContent, videoContent },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      navTop: {}
    }
  },
  computed: {
  },
  mounted() {
    this.getNavTop()
  },
  methods: {
    getNavTop() {
      this.navTop = this.$parent.$refs.navTop
    },
    onLoad() {
      if (this.refreshing) {
        this.list = []
        this.refreshing = false
      }

      if (!this.navTop.searchText) {
        this.loading = false
        return
      }

      this.getData()
    },
    onRefresh() {
      // 清空列表数据
      this.list = []
      this.navTop.page = 1
      this.finished = false

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    reSet() {
      this.list = []
      this.navTop.page = 1
      this.finished = false
      this.loading = true
    },
    getData() {
      this.loading = true
      this.$store.dispatch('home/HomeGetSearch', { page: this.navTop.page, limit: 10, keywords: this.navTop.param.searchText, type: this.$parent.selected * 1 + 1 }).then(res => {
        if (res.code === 0) {
          this.$parent.total = res.count
          if (!res.data.length) {
            this.finished = true
          } else {
            if (this.list.length) {
              this.list.push(...res.data)
            } else {
              this.list = res.data
            }
            this.navTop.page = this.navTop.page * 1 + 1
          }
          if (res.data.length < 10) {
            this.finished = true
          }
        } else {
          this.error = true
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ListOut{
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
  .empty{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    img{
      width:1.205rem;
      height: 1.24rem;
    }
    .text{
      font-size: .13rem;
      font-weight: 500;
      color: #999999;
      margin-top: .15rem;
    }
  }
  .List{
    overflow-y: auto;
    flex: 1;
  }
</style>
