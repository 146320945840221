<template>
  <div class="sj">
    <titleStyle right-text="查看详情 >" more-url="/home/Secretary" :text="fristName" />
    <div v-if="$store.getters.info.fristNotes" v-html=" $setContent($store.getters.info.fristNotes) " />
    <div v-else style="flex: 1; font-size: 1.00rem" class="colCenter it">
      <img :src="require('@/assets/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
import titleStyle from './titleStyle.vue'
export default {
  components: { titleStyle },
  data() {
    return {
      fristName: this.$store.getters.info.fristName
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .sj{
    padding: .2rem .16rem;
  }
</style>
