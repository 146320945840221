<template>
  <div class="third">
    <div class="top">
      <logo style="margin-left:.16rem" />
      <img class="right" :src="require('@/assets/template/third/home/right.png')" @click="$go('/home/notice')">
    </div>
    <!-- 轮播图 -->
    <lunbo />

    <div class="twoItem">
      <div class="item" @click="$go('/home/Secretary')">
        <img :src="require('@/assets/template/third/home/sj.png')">
        <div>{{ this.$store.getters.info.fristName }}<!--第一书记--></div>
      </div>
      <div class="item" @click="$go('/home/GetPbDem')">
        <img :src="require('@/assets/template/third/home/zb.png')">
        <div>支部风采</div>
      </div>
    </div>

    <div class="searchOut">
      <div class="search" @click="$go('/home/search')">
        <img class="searchIcon" :src="require('@/assets/template/third/home/search.png')">
        <div class="text">请输入搜索关键词…</div>
      </div>
    </div>

    <!-- 中间八个图标 -->
    <middleEightIcon />

    <!-- 灰色的线 -->
    <div class="line" />

    <TextAndImg more-url="/menu/main?name=时政要闻&type=1" :param="{top:5,type:1}" text="时政要闻" />

    <div class="line" />

    <TextAndImg more-url="/menu/main?name=党建动态&type=1" :param="{top:5,type:4}" text="党建动态" />

    <div class="line" />

    <ads position="langBan" />

    <div class="line" />

    <TextAndImg more-url="/menu/main?name=专题报道&type=1" :param="{top:5,type:2}" text="专题报道" />

    <div class="line" />

    <!-- 视频 -->
    <videoComponent />

    <div class="line" />

    <ads position="center1Ban" />

    <div class="line" />

    <TextAndImg more-url="/menu/main?name=政策法规&type=1" :param="{top:5,type:3}" text="政策法规" />

    <div class="line" />

    <TextAndImg more-url="/menu/main?name=志愿者活动&type=1" :param="{top:5,type:14}" text="志愿者活动" />

    <div class="line" />

    <!-- <ads position="footBan" /> -->

    <!-- <div class="line" /> -->

  </div>
</template>

<script>
import lunbo from './components/lunbo.vue'
import middleEightIcon from './components/middleEightIcon.vue'
import TextAndImg from './components/content/index.vue'
import ads from './components/adImg.vue'
import videoComponent from './components/video.vue'
export default {
  components: { lunbo, middleEightIcon, TextAndImg, ads, videoComponent },
  data() {
    return {
    }
  },

  mounted() {
    // console.log('this.$store.getters', this.$store.getters)
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.third{
  background-image: linear-gradient(to bottom,#d6484a,#fdf9fa);
  background-size: 3.75rem 6.12rem;
  background-repeat: no-repeat;
  padding-top: .15rem;
  .line{
    height: .13rem;
    background: #f2f3f5;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right{
      width: .22rem;
      margin-right: .13rem;
    }
  }

  .twoItem{
    display: flex;
    justify-content: space-between;
    margin-top: .16rem;
    padding: 0 .17rem;
    .item{
      width: 1.64rem;
      height: .6rem;
      position: relative;
      img{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
      }
      div{
        font-size: .16rem;
        font-weight: bold;
        color: #FFFFFF;
        position: absolute;
        right: .23rem;
        width: max-content;
        z-index: 1;
        line-height: .6rem;
      }
    }
  }

    .searchOut{
    margin-top: .15rem;
    position: relative;
    .search{
      width: 3.43rem;
      height: .36rem;
      background:  #fff;
      border-radius: .18rem;
      display: flex;
      align-items: center;
      margin: auto;
      .searchIcon{
        width: .15rem;
        height: .155rem;
        margin-left: .15rem;
        margin-right: .095rem;
      }
      .text{
        font-size: .14rem;
        font-weight: 500;
        color: #AAAAAA;
        opacity: 0.5;
      }
    }
    .zb{
      width: .40rem;
      height: .40rem;
      position: absolute;
      right: .16rem;
      top: 0;
    }
  }
}
</style>
