<template>
  <div style="padding-top:.28rem">
    <el-form ref="forgetPassword" :rules="forgetRules" label-position="right" :model="formLabelAlign">
      <el-form-item label="请填写账号" prop="account">
        <el-input v-model="formLabelAlign.account" placeholder="请输入账号" />
      </el-form-item>
      <el-form-item label="请填写邮箱" prop="email" style="margin-top:.20rem">
        <el-input v-model="formLabelAlign.email" placeholder="请输入邮箱" />
      </el-form-item>

      <el-form-item label="" style="margin-top:.40rem">
        <el-button :loading="loading" type="primary" class="mybutton" @click="sendCode">发送</el-button>
        <div class="goBack" @click="$go('/home/login')">返回登录 >></div>
      </el-form-item>
    </el-form>

    <el-dialog
      title=""
      :visible.sync="dialogVisible"
    >
      <div class="dialog">
        <div class="text1">发送成功</div>
        <div class="text2">密码已发送到您绑定的邮箱，</div>
        <div class="text2">请登录您的邮箱：</div>
        <div class="text3">【{{ formLabelAlign.email }}】</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="clickIKnow" @click="dialogVisible=false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'PartysiteRegister',

  data() {
    return {
      formLabelAlign: {
        account: '',
        email: ''
      },
      loading: false,
      dialogVisible: false,
      forgetRules: {
        account: [{ required: true, trigger: 'blur', message: '请输入用户名' }, { min: 3, message: '长度3位以上', trigger: 'blur' }],
        email: [{ required: true, trigger: 'blur', message: '请输入邮箱' }]
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) this.$parent.type = 0
    }
  },

  mounted() {

  },

  methods: {
    sendCode() {
      this.$refs.forgetPassword.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('login/AccountForgetPwd', this.formLabelAlign)
            .then((res) => {
              if (res.code === 0) {
                this.dialogVisible = true
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
   .mybutton{
    height: .5rem;
    border-radius: 4px;
    width: 3.15rem;
    margin-left: .3rem;
  }

  .dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    .text1{
      font-size: .20rem;
      font-weight: bold;
      color: #000000;
      margin-top: -.3rem;
      margin-bottom: .26rem;
    }
    .text2{
      font-size: .16rem;
      font-weight: 500;
      color: #000000;
      margin-top: .16rem;
    }
    .text3{
      font-size: .16rem;
      font-weight: bold;
      color: var(--theme);
      margin-top: .18rem;
    }
  }
  .clickIKnow{
    position: relative;
    top: -.1rem;
    width: 2.55rem;
    height: .50rem;
    background: var(--theme);
    border-radius: 2px;
    border: unset!important;
    font-size: .16rem;
    font-weight: bold;
    color: #FFFFFF;
  }
    ::v-deep{
    .el-checkbox{
      margin-left: .3rem;
    }
    .el-form-item__label{
      font-size:.16rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      display: block;
      width: max-content!important;
      margin-left: .3rem;
    }
    .el-form-item__content{
      margin-left: unset!important;
    }
    .el-input{
       text-align: center;
      .el-input__inner{
        width: 3.15rem;
        height: .5rem;

      }
    }
    .el-form-item{
      margin-bottom: .12rem;
    }
    .el-form-item__error{
      width: 3.15rem;
      margin-left: .3rem;
    }

  }
  .goBack{
    font-size: .14rem;
    font-weight: 500;
    color: var(--theme);
    text-align: center;
    margin-top: .2rem;
  }
</style>
