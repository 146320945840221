export default function(type) { // 传1表示删除目前地址(用于删除功能)
  // this.$router.push({ path: this.backUrl, query: this.$route.meta.backQuery || {}})
  const from = window.location.href
  const formPath = from.split('?')[0]
  const state = this.$store.state.app

  for (const index in state.routeHistory) {
    const to = state.routeHistory[index]
    const toPath = to.split('?')[0]
    if (toPath != formPath) {
      state.routeHistory = state.routeHistory.slice(index * 1 + 1)
      if (type == 1) { // 用于删除功能
        state.routeHistory = state.routeHistory.filter(item => item !== from)
      }
      this.$go(to)
      return
    }
  }
  this.$go('/home')
}

