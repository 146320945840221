<template>
  <div class="Readily">
    <div class="content">
      <component
        :is="path"
        ref="editOrCreate"
        style="background: #fff;"
        :form-type="formType"
        :method-path="methodPath"
        @reFresh="reFresh"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      methodPath: { // 接口必传
        create: 'home/ConsultingCreate' // 创建接口
      },
      path: () => import('@/components/tableComponent/editOrCreate.vue'), // 编辑页面,如需要自定义可以引用自己的文件
      param: { type: '' },
      formType: {
        title: {
          label: '标题',
          message: '请输入标题'
        },
        type: { label: '类型', default: '', disabled: true },
        context: { // 富文本
          label: '详细内容',
          type: 'rich'
        }

      }
    }
  },
  created() {
    this.getInit()
  },

  methods: {
    reFresh() {
      this.$goBack(1)
    },
    getInit() {
      this.param = this.$getParams()
      this.formType.type.default = this.param.type
    }
  }
}
</script>

<style lang="scss" scoped>
  .Readily{
    background: #f5f9fa;
    flex: 1;
    .content{
      display: flex;
      flex-direction: column;
      background: #fff;
    }
  }
</style>
