<template>
  <img class="logo" :src="$beforeUrl($store.state.app.info.waplogo)">
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'var(--theme)'
    }
  },

  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
   .logo{
        height: .30rem;
        margin-right: 10px;
      }
</style>
