<template>
  <div class="menu">
    <div class="text">{{ text }}</div>
    <div class="menuList">
      <div v-for="(item,index) in menuList" :key="index" class="menuItem center" @click="goList(item)">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      menuList: []
    }
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      if (this.type == 3) {
        this.menuList = [
          { name: '办事指南', id: 1, url: '/server?selected=0' },
          { name: '公共服务', id: 2, url: '/server?selected=1' }
        ]
      } else {
        this.$store.dispatch('main/NewsCategoryGetCategoryList', { type: this.type || 1 }).then(res => {
          if (res.code === 0) {
            this.menuList = res.data
          }
        })
      }
    },
    goList(item) {
      if (item.url) {
        this.$go(item.url)
      } else {
        this.$go(`/menu/main?id=${item.id}&type=${this.type}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .menu{
    padding-top: .15rem;
    .text{
      font-size: .16rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: .15rem;
    }
    .menuList{
      display: flex;
      flex-wrap: wrap;
      .menuItem{
        width: 1.08rem;
        height: .44rem;
        background: #FFFFFF;
        border-radius: 2px;
        font-size: .16rem;
        font-weight: 500;
        color: #333333;
        margin-right: 0.095rem;
        margin-bottom: .1rem;
      }
      .menuItem:nth-child(3n){
        margin-right: unset!important;
      }
    }
  }
</style>
