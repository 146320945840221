import axios from 'axios'
import { MessageBox } from 'element-ui'
import store from '@/store'
import { throttleUrl } from '@/utils/constant'
import { message } from '@/utils/message'
import router from '../router'

let control = false // 省的让它弹出多个服务器异常
let timer = '' // 定时器id
const obj = { isRepeat: true, msg: '点击太频繁了,休息一下哦~' }

function getThrottleStatus(url) {
  const now = new Date().getTime() // 时间
  for (const item of throttleUrl) {
    if (item.id === url) {
      if (!item.time) { // 表示未触发节流计时
        item.time = now
      } else if ((now - item.time) < (item.delay || 1000)) { // 表示还在延时之内,触发节流
        obj.msg = item.msg || '点击太频繁了,休息一下哦~'
        return true
      }
      item.time = now
      return false
    }
  }
  return false
}

const service = axios.create({
  baseURL: location.protocol + '//site.kyk253.cn/', // url = base url + request url
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (getThrottleStatus(config.url)) {
      return Promise.reject(obj)
    }

    if (store.getters.userInfo) { // 为header添加id
      config.headers['Authorization'] = store.getters.userInfo.token
    }

    if (config.method == 'get' && store.getters.userId) { // 为每次请求添加userId
      if (!config.params) config.params = {}
      config.params.userId = store.getters.userId
    } else if (config.method == 'post' && store.getters.userId) { // post请求是发字符串
      const copy = (config.data || {})
      copy.userId = store.getters.userId
      config.data = copy
    }
    // config.headers['UserId'] = 10000 // window.host.split('.')[0]
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code !== 0) { // 0成功 -1参数错误 -2业务错误 -3须登录 -4权限不足
      if (control !== (res.msg || 'Error') && res.msg != '扫码登录失败,请重新扫码登录' && res.msg != '扫码登录失败') {
        message({
          message: res.msg || 'Error',
          type: 'error',
          duration: 2 * 1000
        })
        control = res.msg || 'Error'
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
          control = false
        }, 2000)
      }

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === -3) { // 须登录
        // to re-login
        MessageBox.confirm('您已注销，可以取消以停留在此页面，或重新登录', '确认登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.commit('app/LOGIN_OUT')
          router.push({ path: '/home/login', query: { relogin: 1 }})
        })
      } else if (res.code == -1 || res.code == -2) {
        return res
      }

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    if (control !== (error.msg || '服务器异常')) {
      message({
        message: error.msg || '服务器异常，请刷新页面重试',
        type: 'error',
        duration: 2 * 1000
      })
      control = error.msg || '服务器异常，请刷新页面重试'
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        control = false
      }, 2000)
    }

    return Promise.reject(error)
  }
)

export default service
