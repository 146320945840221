<template>
  <div class="map" style="flex:1">
    <div ref="mapBox" style="width:3.75rem; min-height:300px;height:100%" />
  </div>
</template>

<script>
import maps from 'qqmap'
export default {
  data() {
    return {
      param: {},
      TXMapParams: {
        map: null, // 地图实例 （地图）
        marker: '', // 地图的标识（标注的点）（地图）
        appkey: this.$key, // appkey是开发者key（地图）
        increaseZB: { // 这个参数是地图的经纬度（地图）
          lat: '', // 纬度
          lng: '' // 经度
        }
      }
    }
  },
  created() {
    this.param = this.$getParams()
    this.TXMapParams.increaseZB.lat = this.param.latitude
    this.TXMapParams.increaseZB.lng = this.param.longitude
  },
  mounted() {
    this.TXMapParams.map || this.initMap()
  },

  methods: {
    initMap() {
      maps.init(this.TXMapParams.appkey, () => { // 参数一：开发者key，参数二：一个函数
        // 其实这个判断是否有map实例，不写也行，在上边已经判断过了
        if (!this.TXMapParams.map) {
          // createZuoBiao方法是 初始化坐标位置，括号两个参数是经纬度
          const myLatLng = this.createZuoBiao(this.TXMapParams.increaseZB.lat, this.TXMapParams.increaseZB.lng)
          this.TXMapParams.map = new maps.Map(this.$refs.mapBox, { // 实例化地图，赋值给data中的map
            center: myLatLng, // 目前的位置
            zoom: 13,
            draggable: true, // 是否可移动
            scrollwheel: true, // 是否可滚动
            disableDoubleClickZoom: false
          })
        }
        // 初始化marker标识
        this.TXMapParams.marker = new maps.Marker({
          position: this.createZuoBiao(this.TXMapParams.increaseZB.lat, this.TXMapParams.increaseZB.lng), // 坐标位置
          map: this.TXMapParams.map // 实例化的地图
        })
        // 添加信息窗口
        const info = new maps.InfoWindow({
          map: this.TXMapParams.map
        })
        // 获取标记的可拖动属性
        info.open()
        info.setContent(`<div style='padding:5px'>
        <div style='font-size:15px;margin-bottom:5px'>${this.param.name}</div><div style='margin-bottom:5px'>电话：${this.param.tel}</div><div>地址：${this.param.address}</div>
        </div>`)
        info.setPosition(this.TXMapParams.marker.getPosition())
        maps.event.addListener(this.TXMapParams.marker, 'click', () => {
          info.open()
        })
      })
    },
    // 创建经纬度
    createZuoBiao(myLatitude, myLongitude) {
      return new maps.LatLng(myLatitude, myLongitude)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
