<template>
  <div v-loading="loading" class="detailOut">
    <div v-if="!loading" class="detail">
      <div
        v-for="item in newDataList"
        :key="item.id"
        class="item"
        :class="{statusItem:item.type==='status',middleItem:!['status','title','rich','noTop'].includes(item.type),richItem:item.type==='rich'}"
      >
        <div v-if="item.type!=='title' && item.type!=='status'" class="label" :style="{width:item.type!=='rich' && item.type !== 'noTop'?labelWidth:'max-content'}">
          {{ item.label }}：</div>
        <!-- 标题 -->
        <div v-if="item.type==='title'" style="flex:1" class="title twoLine"><div style="width:.64rem;opacity: 0;display: inline-block;">1</div>{{ item.value }}</div>
        <!-- 状态 -->
        <div v-else-if="item.type==='status'" class="status" :style="{color:item.textColor[item.value]}">
          <div
            :style="{background:item.textColor[item.value]}"
            class="statusBackground"
          />{{ item.value }}</div>
        <!-- 富文本 -->
        <div v-else-if="item.type==='rich'" style="flex:1;word-break: break-all;" class="rich" v-html="$setContent(item.value)" />
        <!-- 常用文本 -->
        <div v-else style="flex:1;word-break: break-all;">{{ item.value }}</div>
      </div>
    </div>
    <div v-if="!loading" class="handleOut">
      <div v-if="replyData.length && strStatus!=='已取消'" class="handle">
        <div class="handleTitle">—— 审核详情 ——</div>
        <div v-for="item in replyData" :key="item.id" :style="{display:item.value?'':'none'}" class="item">
          <div class="label">{{ item.label }}：</div>
          <div class="right" :style="{color:item.textColor? item.textColor[item.value] : ''}">{{ item.value }}</div>
        </div>
      </div>
      <div v-if="!loading" class="bottomButton">
        <div :style="{display:!strStatus || statusData[routeName][strStatus].del || ''}" class="del center click" @click="$showNotice(()=>deleteItem())">删除</div>
        <div :style="{display:!strStatus || statusData[routeName][strStatus].cancel || ''}" class="cancel center click" @click="$showNotice(()=>cancelsMethod(),'确定取消?')">取消</div>
        <div :style="{display:!strStatus || statusData[routeName][strStatus].reset || ''}" class="reset center click" @click="reEdit">重新编辑提交</div>
      </div>
    </div>

  </div>
</template>

<script>
import { statusData } from '@/views/mine/sonPage/common.js'
export default {
  props: {
    detailData: { // 详情内容
      type: Array,
      default: () => []
    },
    labelWidth: {
      type: String,
      default: '.90rem'
    },
    replyDataList: { // 回复内容
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      param: {},
      newDataList: [],
      replyData: [],
      loading: true,
      statusData,
      routeName: 'Demand',
      strStatus: ''
    }
  },

  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      this.param = this.$getParams()
      this.loading = true
      this.$store.dispatch(this.$parent.methodPath.getInfoFromId, { id: this.param.id }).then(res => {
        if (res.code === 0) {
          this.responseData = res.data
          this.newDataList = this.detailData.map(item => {
            item.value = res.data[item.id] || '--'
            return item
          })
          if (res.data.replyData) { // 回复后才有
            this.replyData = this.replyDataList.map(item => {
              item.value = res.data.replyData[item.id]
              return item
            })
          } else {
            this.replyData = this.replyDataList.map(item => {
              item.value = res.data[item.id]
              return item
            })
            this.replyData = this.replyData.filter(item => item.value).length ? this.replyData : []
          }
          this.strStatus = res.data['strStatus']
        }
        this.$nextTick(() => {
          this.loading = false
        })
      })
    },
    deleteItem() {
      this.$store.dispatch(this.$parent.methodPath.del, { id: this.param.id }).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.$goBack(1)
        }
      })
    },
    cancelsMethod() {
      this.$store.dispatch(this.$parent.methodPath.cancel, { id: this.param.id }).then(res => {
        if (res.code === 0) { // 删除成功
          this.$message.success('取消成功')
          this.getInit()
        }
      })
    },
    reEdit() {
      window.sessionStorage.setItem('responseData', JSON.stringify(this.responseData))
      this.$go('/idea/DemandCreate?edit=1')
    }
  }
}
</script>

<style lang="scss" scoped>
.middleItem{
  .label{
    color: #999999;
  }
  background: #f2f3f5;
  margin-top: unset!important;
  padding-left: .12rem;
  padding-top: .05rem;
  padding-bottom: .05rem;
}
.item:nth-child(2){
  padding-top: .1rem!important;

}
.item:nth-child(7){
 padding-bottom: .1rem!important;
}

.richItem{
  position: relative;
  padding-top: .2rem;
  .rich{
    flex: 1;
  }
  &::before{
    height: 1px;
    background: #DDDDDD;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    content: "";
    width: 3.43rem;
  }
}
.status{
  width: .54rem;
  height: .23rem;
  border-radius: 2px;
  font-size: .14rem;
  font-weight: 400;
  position: relative;
  line-height: .23rem;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  .statusBackground{
    content: '';
    display: inline-block;
    opacity: .1;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right:0;
    bottom: 0;
  }
}
.statusItem{
  position: absolute;
  top: 0;
  left: 0;
  margin-top: unset!important;
}
.detailOut{
  overflow-x: hidden;
  padding: .18rem .16rem .0rem .16rem;
  display: flex;
  flex-direction: column;
  .detail{
    padding-bottom: .1rem;
    position: relative;
    .item{
      display: flex;
      font-size: .15rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: .15rem;
      .title{
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
        margin-bottom: .2rem;
      }

    }
    .item:first-child{
      margin-top: unset!important;
      position: relative;
      &::after{
        height: 1px;
        background: #DDDDDD;
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .item:nth-child(2){
      margin-top: .2rem!important;
    }
  }
  .handleOut{
    flex: 1;
    background: #f6f8fa;
    width: 3.75rem;
    margin-left: -.16rem;
    padding-bottom: .3rem;
    .bottomButton{
      display: flex;
      padding: .16rem .16rem 0 .16rem;
      &>div:nth-child(2),&>div:nth-child(3){
        margin-left: .15rem;
      }
      .del{
        height: .50rem;
        border: 1px solid var(--theme);
        border-radius: 2px;
        font-size: .16rem;
        font-weight: bold;
        color: var(--theme);
        margin-left:auto;
        margin-right: auto;
        flex: 1;
      }
      .cancel{
        height: .50rem;
        border: 1px solid #999;
        border-radius: 2px;
        font-size: .16rem;
        font-weight: bold;
        color: #999;
        margin-left:auto;
        margin-right: auto;
        flex: 1;
      }
      .reset{
        height: .50rem;
        border-radius: 2px;
        background: var(--theme);
        font-size: .16rem;
        font-weight: bold;
        color: #fff;
        margin-left:auto;
        margin-right: auto;
        flex: 1;
      }
    }

    .handle{

    padding: .25rem .17rem .18rem .17rem;

    .handleTitle{
      font-size: .18rem;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-bottom: .25rem;
    }
    .item{
      display: flex;
      font-size: .16rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: .15rem;
      .label{
        width: .85rem;

      }
      .right{
        word-break: break-all;
        flex:1
      }
    }
  }
  }

}

</style>
