<template>
  <div v-loading="loading" class="outs">
    <div class="content1">
      <itemContent :data-list="dataList" />
    </div>
  </div>
</template>

<script>
import itemContent from './TextAndImg.vue'
export default {
  components: {
    itemContent
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    },
    param: {
      type: Object,
      default: () => {
        return { top: 6, type: 1 }
      }
    }
  },
  data() {
    return {
      dataList: [],
      loading: true
    }
  },

  mounted() {},
  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      this.$store.dispatch('home/HomeGetSzNews', this.param).then((res) => {
        if (res.code === 0) {
          this.dataList = res.data
        } else {
          this.$message.error(res.msg || '请求失败,请刷新重试')
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .outs{
    padding: .25rem .16rem .20rem .16rem;
  }
</style>
