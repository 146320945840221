<template>
  <div class="zbfc">
    <div class="top">
      <div class="title">{{ info.pbName }}</div>
      <div v-if="info.isFindPb" class="isGood redDiv">{{ info.pbHonorName }}</div>
      <div class="address">地址：{{ info.address || '--' }}</div>
      <div class="fourItem">
        <div class="item redDiv">
          <div class="title">党员数量</div>
          <div class="count">{{ info.pmCount }}</div>
        </div>
        <div class="item redDiv">
          <div class="title">下级党组织</div>
          <div class="count">{{ info.childCount }}</div>
        </div>
        <div class="item redDiv">
          <div class="title">专题数</div>
          <div class="count">{{ info.newsCount }}</div>
        </div>
        <div class="item redDiv">
          <div class="title">活动会议</div>
          <div class="count">{{ info.meetingCount }}</div>
        </div>
      </div>
      <div class="summary">
        <div class="title">组织概述：</div>
        <div class="content">{{ info.summary }}</div>
      </div>

      <div class="goDetail center" @click="$go(`/home/GetPbDem/Detail?id=${info.id}`)">查看党组织详情 ></div>
    </div>
    <div v-if="info.childData && info.childData.length" class="bottomOrganization">
      <div class="title">下级党组织</div>
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item v-for="(item,index) in info.childData" :key="index" :name="index+''">
          <template slot="title">
            <div class="customTitle">
              <div v-if="item.isFindPb" class="isGood2 redDiv center">{{ item.pbHonorName }}</div>
              <div class="titles" @click="$go(`/home/GetPbDem?id=${item.id}`)">{{ item.pbName }}</div>

            </div>
          </template>
          <div class="item">
            <div style="margin-top:.15rem">支部地址：{{ item.address }}</div>
            <div>组织概述：{{ item.summary }}</div>
            <div style="margin-bottom:.30rem">
              <div>下级支部：{{ item.childCount }}个</div>
              <!-- <div v-if="item.childCount" class="center click" @click="$go(`/home/GetPbDem?id=${item.id}`)">查看下级党组织></div> -->
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
      },
      activeName: ''
    }
  },
  watch: {
    $route() {
      this.getData()
      setTimeout(() => {
        document.querySelector('.zbfc').scrollTop = 0
      }, 100)
    }
  },

  created() {
    this.getData()
  },
  methods: {
    getData() {
      const id = this.$getParams().id
      const param = {}
      if (id) param.pbId = id
      this.$store.dispatch('main/PbGetPbDem', { ...param }).then(res => {
        if (res.code === 0) {
          this.info = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .isGood2{
      width: unset!important;
      height: .24rem;
      background: rgba(194, 22, 22, 0.1);
      border: 1px solid var(--theme);
      padding: 0 .1rem;
      margin-right: .1rem;
      line-height: .24rem;
    }
.zbfc{
  .top{
    background: #fff;
    padding: .25rem .16rem .39rem .16rem;
    .title{
      font-size: .20rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: .15rem;
    }
    .isGood{
      margin-bottom: .15rem;
      width: unset!important;
      height: .24rem;
      background: rgba(194, 22, 22, 0.1);
      border: 1px solid var(--theme);
      padding: 0 .1rem;
      line-height: .24rem;
    }
    .address{
      font-size: .13rem;
      font-weight: 500;
      color: #999999;
      margin-bottom: .3rem;
    }
    .fourItem{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item{
        width: 1.65rem;
        height: .90rem;
        margin-bottom: .13rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
          font-size: .20rem;
          color: var(--theme);
        }
        .count{
          font-size: .16rem;
        }
      }
    }
    .summary{
      margin-top: .17rem;
      .title{
        font-size: .18rem;
        font-weight: bold;
        color: #333333;
        margin-bottom: .2rem;
      }
    }
    .goDetail{
      margin-top: .35rem;
      height: .44rem;
      background: var(--theme);
      border-radius: 2px;
      font-size: .16rem;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
.bottomOrganization{
    padding: .25rem .16rem;
    background: #f3f3f5;
    .title{
      display: flex;
      align-items: center;
      font-size: .18rem;
      font-weight: bold;
      color: #333333;
      line-height:.18rem;
      margin-bottom: .2rem;
      &::before{
        width: .04rem;
        height: .18rem;
        background: var(--theme);
        margin-right: .06rem;
        content: "";
        display: inline-block;
      }
    }
    .item{
      &>div:nth-child(1){
        margin-bottom: .15rem;
      }
      &>div:nth-child(2){
        margin-bottom: .15rem;
      }
      &>div:nth-child(3){
        display: flex;
        justify-content: space-between;
        align-items: center;
        &>div:nth-child(1){
          font-size: .16rem;
          font-weight: 400;
          color: #666666;
        }
        &>div:nth-child(2){
          width: 1.40rem;
          height: .28rem;
          border: 1px solid var(--theme);
          border-radius: 4px;
          font-size: .14rem;
          font-weight: 400;
          color: var(--theme);
        }
      }
      &>div{
        font-size: .16rem;
        font-weight: 400;
        color: #666666;
      }
    }
  }
  .customTitle{
    display: flex;
    align-items: center;
    .titles{
      font-size: .18rem;
      font-weight: 900;
      color: #000;
      margin-bottom: unset;
      text-decoration: underline;
    }
  }
  ::v-deep{
    .el-collapse-item__header{
      border: 1px solid #EBEEF5!important;
      background: #fff!important;
      padding:.20rem;
    }
    .el-collapse-item__wrap{
      padding-left: .20rem;
      padding-right: .20rem;
      background: #fff!important;
      border-bottom: unset;
    }
    .el-collapse-item{
      margin-bottom: .20rem;
    }
    .el-collapse-item__content{
      padding-bottom: unset!important;;
    }
    .el-collapse{
      border-bottom: unset!important;
    }
  }
}

</style>
