<template>
  <div class="Readily">
    <div class="content">
      <component
        :is="path"
        ref="editOrCreate"
        style="background: #fff;"
        :form-type="formType"
        :method-path="methodPath"
        @reFresh="reFresh"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkTel = (rule, value, callback) => { // 编辑验证
      const re = /^1[3456789]{1}\d{9}$/
      if (!re.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      }
      callback()
    }
    var options = [{ name: '选项一', id: '1' }, { name: '选项二' }, { name: '选项三' }]
    var options2 = [{ name: '选项一', id: '1' }, { name: '选项二' }, { name: '选项三' }]

    return {
      methodPath: { // 接口必传
        create: 'personal/DemandCreate', // 创建接口
        edit: 'home/DemandUpdate' // 编辑接口
      },
      path: () => import('@/components/tableComponent/editOrCreate.vue'), // 编辑页面,如需要自定义可以引用自己的文件
      formType: {
        name: { label: '项目名称', message: '请输入项目名称' },
        type: { label: '项目类型', type: 'select', options, message: '请选择项目类型' }, // 下拉选择
        pbId: { label: '对接部门', type: 'select', options: options2, message: '请选择对接部门' }, // 下拉选择
        budget: { label: '项目资金预算', type: 'number', message: '请输入资金预算', afterDiv: { // 这个用于在input后面插入提示语(一直显示),暂时只能用于type为默认
          text: '元', // 文字,必传
          customStyle: { color: '#333', width: 'max-content', fontWeight: '400' } // 样式,非必传,为后面div的样式
        }},
        days: { label: '计划周期', message: '请输入计划周期', type: 'int', afterDiv: { // 这个用于在input后面插入提示语(一直显示),暂时只能用于type为默认
          text: '天', // 文字,必传
          customStyle: { color: '#333', width: 'max-content', fontWeight: '400' } // 样式,非必传,为后面div的样式
        }},
        context: { // 富文本
          label: '详细详情',
          type: 'rich'
        },
        contacts: { label: '联系人', message: '请输入联系人' },
        tel: { label: '联系电话', validator: checkTel },
        email: { label: '邮箱', message: '请输入邮箱' },
        company: { label: '单位', message: '请输入单位' },
        address: { label: '联系地址', message: '请输入联系地址' }
      },
      param: {}
    }
  },

  mounted() {
    this.init()
  },
  created() {
    this.getTypeList() // 获取下拉分类
  },

  methods: {
    getTypeList() {
      this.$store.dispatch('home/PbDropDownPb').then(res => {
        if (res.code === 0) {
          this.formType.pbId.options = res.data
        }
      })
      this.$store.dispatch('home/DemandDropDownDemand').then(res => {
        if (res.code === 0) {
          this.formType.type.options = res.data
        }
      })
    },
    reFresh() {
      this.$go('/mine/Demand')
    },
    init() {
      this.param = this.$getParams()
      if (this.param.edit) {
        let data = window.sessionStorage.getItem('responseData')
        if (data) {
          console.log('data', data)
          data = JSON.parse(data)
          setTimeout(() => {
            this.$refs.editOrCreate.itemInfo = data
          }, 100)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    .Readily{
    background: #f5f9fa;
    flex: 1;
    .content{
      display: flex;
      flex-direction: column;
      background: #fff;
    }
  }
</style>
