<template>
  <div class="search">
    <navTop ref="navTop" />
    <div v-if="text" class="count">
      你搜索<span class="redText">“{{ text }}”</span>共有{{ total }}条记录
    </div>
    <div class="selectedOut">
      <div :class="{selected:selected==0}" @click="changeSelected(0)">文章</div>
      <div :class="{selected:selected==1}" @click="changeSelected(1)">视频</div>
    </div>

    <div class="content">
      <searchContent ref="searchcontent" />
    </div>
  </div>
</template>

<script>
import navTop from './components/navTop.vue'
import searchContent from './components/searchContent.vue'
export default {
  components: { navTop, searchContent },
  data() {
    return {
      selected: 0,
      text: '',
      total: 0
    }
  },

  mounted() {

  },

  methods: {
    changeSelected(select) {
      if (select === this.selected) return
      else {
        this.$refs.searchcontent.reSet()
        this.$changeParam({ selected: select }, true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .search{
    display: flex;
    flex-direction: column;
    .count{
      font-size: .15rem;
      font-weight: 500;
      color: #333333;
      padding: .105rem .16rem;
      border-bottom: 1px solid #eee;
      span{
        color: var(--theme);
      }
    }
    .content{
      flex: 1;

    }
    .selectedOut{
      padding: .155rem .15rem 0 .15rem;
      display: flex;
      div{
        font-size: .15rem;
        font-weight: 500;
        color: #999999;
        margin-right: .245rem;
        padding-bottom: .045rem;
      }
      .selected{
        color: var(--theme);
        border-bottom: 2px solid var(--theme);
      }
    }
  }
</style>
