<template>
  <div class="menuListOut" style="height:100%;background: #f2f3f5;">
    <menuList :type="1" text="新闻栏目" />
    <menuList :type="2" text="党建视频" />
    <menuList :type="4" text="三务公开" />
    <menuList :type="5" text="困难帮扶" />
    <menuList :type="3" text="办事服务" />
  </div>
</template>

<script>
import menuList from './components/list.vue'
export default {
  components: { menuList },
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .menuListOut{
    padding: 0.1rem .16rem .25rem .16rem;
  }
</style>
