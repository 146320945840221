<template>
  <div class="login">
    <div class="navTop center">
      <div style="height:0.5rem" class="center back" @click="$goBack()">
        <img class="backImg" :src="require(`@/assets/back-black.png`)">
      </div>
      {{ $store.state.app.routeName }}
      <div style="height:0.5rem" class="center home" @click="$go('/home')">
        <img class="backImg" :src="require(`@/assets/home.png`)">
      </div>
    </div>
    <!-- 注册 -->
    <register class="content" />
  </div>
</template>

<script>
import register from './components/register.vue'
export default {
  components: { register },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
.login{
  display: flex;
  flex-direction: column;
}
  .navTop{
    height: 0.5rem;
    font-size: .24rem;
    font-weight: bold;
    color: #333333;
    position: relative;
    border-bottom: 1px solid #eee;
    .back{
      position: absolute;
      left: .16rem;
      .backImg{
        width: .20rem;
      }
    }
    .home{
      position: absolute;
      right: .16rem;
      .backImg{
        width: .20rem;
      }
    }

  }
  .content{
    flex: 1;
    overflow-y:auto;
  }
</style>
