
const routes = [
  {
    path: '/menu',
    component: () => import('@/views/menu'),
    meta: { name: '菜单导航' }

  },
  {
    path: '/menu/main',
    component: () => import('@/views/menu/sonPage/main'),
    meta: { name: '' }
  },
  {
    path: '/menu/main/detail',
    component: () => import('@/views/menu/sonPage/detail'),
    meta: { name: '' }
  }
]

export default routes
