<template>
  <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">

    <div>
      <el-form-item prop="account" class="account">
        <div class="account-img">
          <img :src="require(`@/assets/login/${$store.getters.moduleName}/6.png`)">
        </div>
        <el-input
          ref="account"
          v-model="loginForm.account"
          placeholder="请输入用户名"
          name="account"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>
      <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
        <el-form-item prop="password" class="account">
          <div class="account-img">
            <img :src="require(`@/assets/login/${$store.getters.moduleName}/4.png`)">
          </div>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="handleLogin"
          />
          <div class="account-img2">
            <img v-show="!passwordType" :src="require(`@/assets/login/${$store.getters.moduleName}/5.png`)" @click="passwordType='password'">
            <img v-show="!!passwordType" :src="require(`@/assets/login/${$store.getters.moduleName}/7.png`)" @click="passwordType=''">
          </div>
        </el-form-item>
      </el-tooltip>

      <div class="backPassword">
        <div class="saveme">
          <el-checkbox v-model="checked">记住账号密码</el-checkbox>
        </div>
        <div @click="$go('/home/login/forget')">忘记密码?</div>
      </div>
      <div :loading="loading" class="mybutton" @click="handleLogin">登录</div>
      <div :loading="loading" class="mybuttonWx center" @click="goUrl">
        <img class="wxImg" :src="require('@/assets/wx.png')">
        <div>微信登录</div>
      </div>

      <div class="register" @click="$go('/home/login/register')">还没有账号，去注册>></div>

      <img class="bottomImg" :src="require('@/assets/bottom.png')">
    </div>
  </el-form>
</template>

<script>
export default {
  name: 'PartysiteLogin',

  data() {
    return {
      loginForm: {
        account: '',
        password: ''
      },
      loginRules: {
        account: [{ required: true, trigger: 'blur', message: '请输入用户名' }, { min: 3, message: '长度3位以上', trigger: 'blur' }],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }, { min: 6, message: '长度6位以上', trigger: 'blur' }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      otherQuery: {},
      checked: true

    }
  },
  computed: {
    parent() {
      return this.$parent
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    let usersave = window.localStorage.getItem('usersave')
    if (usersave) {
      this.checked = true
      usersave = JSON.parse(usersave)
      this.loginForm.account = usersave.account
      this.loginForm.password = usersave.password
    } else {
      this.checked = false
    }
  },
  mounted() {
    if (this.loginForm.account === '') {
      this.$refs.account.focus()
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus()
    }
  },

  methods: {
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('login/AccountLogin', this.loginForm)
            .then((res) => {
              if (this.checked) {
                window.localStorage.setItem('usersave', JSON.stringify({ account: this.loginForm.account, password: this.loginForm.password }))
              } else {
                window.localStorage.removeItem('usersave')
              }
              if (res.code === 0) {
                this.$store.commit('app/LOGIN', res.data)
              }
              this.loading = false
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    },
    goUrl() {
      if (this.$parent.urlData) {
        window.location.href = this.$parent.urlData.path
      } else {
        this.$message.error('尝试登陆中,请稍后')
      }
    }

  }
}
</script>

<style lang="scss" scoped>
  $dark_gray:#889aa4;
$light_gray:#eee;
::v-deep{
  .el-input {
    display: flex;
    align-items: center;

    height: unset!important;}

    input {
    background: transparent;
    border: 0px;
    -webkit-appearance: none;
    border-radius: 0px;
    height: unset!important;

    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px #fff inset !important;
      -webkit-text-fill-color: unset !important;
      border-radius: 10px;
    }
  }
  .el-form-item{
    margin-bottom: .3rem;
  }
}
  .login-form {
    padding: .3rem;
    position: relative;
    box-sizing: border-box;
    .backPassword{
      font-size: .14rem;
      font-weight: 500;
      color: #999999;
      margin-top: -.05rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .register{
      font-size: .14rem;
      font-weight: 500;
      color: var(--theme);
      text-align: center;
      margin-top: .3rem;
    }
    .account{
      height: .55rem;
      background: #f2f3f5;
      border-radius: 5px;
      ::v-deep{
        .el-form-item__content{
          height: 100%;
          line-height: unset!important;
          display: flex;
          align-items: center;
        }
        .el-input--medium{
          flex:1
        }
      }
      .account-img{
        width: .41rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          margin-left: .025rem;
          width: .2rem;
        }
      }
      .account-img2{
        width: .41rem;
        height: 100%;
        display: flex;
        align-items: center;
        img{
          cursor: pointer;
        }
        & img:nth-child(1){
          width: .26rem;
        }
        & img:nth-child(2){
          width: .26rem;

        }
      }
    }

  }

.mybutton{
  height: .5rem;
  border-radius: 2px;
  width: 100%;
  background: var(--theme);
  line-height: .5rem;
  text-align: center;
  font-size: .16rem;
  font-weight: bold;
  color: #FFFFFF;
  margin-top: .28rem;
}
.mybuttonWx{
  height: .5rem;
  border: 1px solid var(--theme);
  border-radius: 2px;
  font-size: .16rem;
  font-weight: bold;
  color: var(--theme);
  margin-top: .2rem;
  .wxImg{
    width: .21rem;
    margin-right: .1rem;
  }
}

.bottomImg{
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  height: 3.08rem;
}
</style>
